import React from "react";
import { makeStyles, Typography } from "@material-ui/core"
import { Theme } from "../../../theme";
import classNames from 'classnames';
import { AppData_pageTitle } from '../../../globalTypes/AppData';

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    margin: spacing(0, 0, 3)
  },
  title: {
    fontWeight: 300,
    lineHeight: 1.5,
  },
  brand: {}
}));

export type PageTitleProps = {
  title: AppData_pageTitle;
  showBrand?: boolean
  dense?: boolean;
} & StandardProps;

const PageTitle: React.FC<PageTitleProps> = ({
                                               className,
                                               title,
                                               showBrand = true,
                                               dense = false,
                                               ...others
                                             }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)} {...others}>
      <Typography variant={dense ? 'h5' : 'h3'} className={classes.title} align={'center'}>
        {title.text}&nbsp;
      </Typography>
    </div>
  );
};

export default PageTitle;
