import React, { useContext } from "react";
import classNames from "classnames";
import { AppBar, Button, Divider, Hidden, makeStyles, Menu, MenuItem, Toolbar } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { userContext } from "../../../state/userContext";
import TranslateContext from "../../../TranslateContext";
import { fontFamilyList, Theme } from "../../../theme";
import Link from "../../common/Link";
import LogoutButton from './LogoutButton';
import { AppData_header_menu } from "../../../globalTypes/AppData";

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    marginBottom: spacing(2),
    background: "#000000",
    position: "fixed",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },

  button: {
    margin: spacing(1),
    textTransform: "none"
  },

  hoverNoneButton: {
    margin: spacing(1),
    textTransform: "none",
    background: "black",
    '&:hover': {
      background: "black",
   },
  },

  logo: {
    height: "40px",
  },

  menu: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center"
  },

  link: {
    textTransform: "none",
    padding: spacing(0, 1),
    color: palette.common.white,
    fontWeight: 300,
    fontSize: '1.3rem',
    fontFamily: fontFamilyList.openSans
  },

  openMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: "100%",
    borderRadius: "0px",
    top: '75px!important',
    maxWidth: '100%',
    right: '0px',
    left: 'initial!important',
    height: '100vh',
    maxHeight: '100%',
    background: "black",
    color: "white",
  },

  openMenuDesktop: {
    width: '300px',
    borderRadius: "0px",
    background: "black",
    color: "white",
    top: '84px!important',
    right: '0px',
    left: 'initial!important'
  },

  popupMenuDesktop: {
    cursor: "pointer",
    color: "white",
    fontWeight: 100,
    fontSize: '1.1rem',
    paddingLeft: "20px",
    fontFamily: 'Fahkwang, Regular',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },

  popupMenuDesktopMenuItem: {
    padding: '20px 30px',
    display: "block",
    textAlign: "center",
    fontFamily: 'Fahkwang, Regular',
    fontSize: '1.1rem!important',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },

  marginDiv: {
    marginBottom: '120px',
  },
}));

const Header: React.FC<StandardProps> = ({ className, ...others }) => {
  const classes = useStyles();
  const { loggedUser } = useContext(userContext);
  const {
    header: { logo, addRecipe, register, login, myProfile, menu },
    userProfile: {
      addBoard
    },
  } = useContext(TranslateContext);

  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      {...others}
      color={"transparent"}
      className={classNames(className, classes.root)}>
      <Toolbar className={classes.toolbar}>
        <Hidden mdUp>
        < Link to={"/"}>
          <img src={logo.url} alt={logo.alt} className={classes.logo} style={{maxHeight: "30px", marginTop: "-5px"}} />
        </Link>
        {loggedUser && (
          <LogoutButton handleClose={handleClose} />
        )}
        <div
          aria-label="more"
          aria-controls="long-menu-desktop"
          aria-haspopup="true"
          onClick={handleClick}>
          <span className={classes.popupMenuDesktop}>MENU</span>
        </div>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            PopoverClasses={{paper: classes.openMenu}}
            onClose={handleClose}
          >
            {!loggedUser && (
              <div>
                <MenuItem className={classes.popupMenuDesktopMenuItem} onClick={handleClose} component={RouterLink} to={"/sign-up"}>{register}</MenuItem>
                <MenuItem className={classes.popupMenuDesktopMenuItem} onClick={handleClose} component={RouterLink} to={"/login"}>{login}</MenuItem>
              </div>
            )}

            {loggedUser && menu
              .filter((menuItem: {private: boolean;}) => (menuItem.private))
              .map((data: AppData_header_menu, index: number) => (
                <MenuItem className={classes.popupMenuDesktopMenuItem} onClick={handleClose} component={RouterLink} key={index} to={data.url}>{data.name}</MenuItem>
              ))}

              {loggedUser && (
                <div>
                  <Divider />
                  <MenuItem className={classes.popupMenuDesktopMenuItem} onClick={handleClose} component={RouterLink} key={'myProfile'} to={String(loggedUser.id)}>{myProfile}</MenuItem>
                </div>
              )}

            <Divider />
            {menu
              .filter((menuItem: {private: boolean;}) => !menuItem.private)
              .map((data: AppData_header_menu, index: number) => (
                <MenuItem className={classes.popupMenuDesktopMenuItem} onClick={handleClose} component={RouterLink} key={index} to={data.url}>{data.name}</MenuItem>
              ))
            }
            <div className={classes.marginDiv}>
            </div>
          </Menu>
        </Hidden>

        <Hidden smDown>
        < Link to={"/"}>
          <img src={logo.url} alt={logo.alt} className={classes.logo} style={{maxHeight: "30px", marginTop: "-5px"}} />
        </Link>
          <div className={classes.menu}>
            <div style={{}}>
            {loggedUser ? (
              <>
                <Button
                  component={RouterLink}
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  style={{color: "white", background: "none", fontSize: "1.1rem", marginBottom: "10px", fontWeight: 300, textTransform: "uppercase"}}
                  title={addBoard}
                  to={"/add-board"}
                >
                  {addBoard}
                </Button>

                <Button
                  component={RouterLink}
                  variant="contained"
                  color="secondary"
                  className={classes.hoverNoneButton}
                  style={{color: "white", background: "none", fontSize: "1.1rem", marginBottom: "10px", fontWeight: 300, textTransform: "uppercase"}}
                  title={addRecipe}
                  to={"/add-pin"}
                >
                  {addRecipe}
                </Button>
                <Button
                  component={RouterLink}
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  style={{color: "white", background: "none", fontSize: "1.1rem", marginBottom: "10px", fontWeight: 300, textTransform: "uppercase"}}
                  title={myProfile}
                  to={`/${loggedUser.id}`}
                >
                  {myProfile}
                </Button>
                <LogoutButton />
              </>
            ) : (<>
              <Button
                component={RouterLink}
                variant="contained"
                color="secondary"
                className={classes.hoverNoneButton}
                style={{color: "white", fontSize: "1.1rem", fontFamily: 'Fahkwang, Regular', fontWeight: 300, textTransform: 'uppercase',}}
                to={"/sign-up"}
                title={register}
              >
                {register}
              </Button>
              </>
            )}
            </div>
            <div
              aria-label="more"
              aria-controls="long-menu-desktop"
              aria-haspopup="true"
              onClick={handleClick}>
              <span className={classes.popupMenuDesktop}>MENU</span>
            </div>
            <Menu
              id="long-menu-desktop"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              PopoverClasses={{paper: classes.openMenuDesktop}}
              onClose={handleClose}
            >
              {!loggedUser && (
                <div>
                  <MenuItem className={classes.popupMenuDesktopMenuItem} onClick={handleClose} component={RouterLink} to={"/login"}>{login}</MenuItem>
                </div>
              )}

              {loggedUser && menu
                .filter((menuItem: {private: boolean;}) => (menuItem.private))
                .map((data: AppData_header_menu, index: number) => (
                  <MenuItem className={classes.popupMenuDesktopMenuItem} onClick={handleClose} component={RouterLink} key={index} to={data.url}>{data.name}</MenuItem>
                ))}

              {loggedUser && (
                <div>
                  <Divider />
                  <MenuItem className={classes.popupMenuDesktopMenuItem} onClick={handleClose} component={RouterLink} key={'myProfile'} to={String(loggedUser.id)}>{myProfile}</MenuItem>
                </div>
              )}

              <Divider />
              {menu
                .filter((menuItem: {private: boolean;}) => !menuItem.private)
                .map((data: AppData_header_menu, index: number) => (
                  <MenuItem className={classes.popupMenuDesktopMenuItem} onClick={handleClose} component={RouterLink} key={index} to={data.url}>{data.name}</MenuItem>
                ))
              }
            </Menu>
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
