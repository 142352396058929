import React, { FormEvent, useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Hidden, Button, Grid, makeStyles, Paper, TextField, Typography } from "@material-ui/core";

import { globalFormTopMargin, Theme } from "../../theme";
import { useMutationApi } from "../../hooks/api";
import { getErrorMessage } from "../common/errorMessage";
import { Alert } from "@material-ui/lab";
import { userContext } from "../../state/userContext";
import TranslateContext from "../../TranslateContext";
import FacebookLogin from '../components/facebookLogin/FacebookLogin';
import PageTitle from '../components/pageTitle/pageTitle';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    marginTop: globalFormTopMargin,
    [breakpoints.down("sm")]: {
      padding: spacing(1, 0, 1, 0),
      marginTop: spacing(0),
      display: "flex"
    }
  },

  margin: {
    margin: spacing(2),
    [breakpoints.down("sm")]: {
      margin: spacing(0),
    },
  },

  actionButtons: {
    alignItems: "center",
    [breakpoints.down("sm")]: {
      flexDirection: "column-reverse"
    },
  },

  button: {
    textAlign: "center",
    width: '100%'
  },

  fieldIcons: {
    [breakpoints.down("sm")]: {
      display: 'none'
    },
  },

  paper: {
    
    margin: "0 auto",
    maxWidth: 500,
    boxShadow: 'none',
    [breakpoints.down("sm")]: {
      maxWidth: "unset",
      width: "100%"
    },
  },

  textfield: {
    background: "#000000",
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    "&:hover, &:focus": {
      outline: "10",
      outlineOffset: "10px",
    },
  },

  multilineColor: {
    color:'white',
    textAlign: "center",
    shrink: true,
    fontSize: "18px",
  },

  subTitle: {
    fontFamily: "Amiri",
    fontSize: "17px"
  }
}));

const LoginPage: React.FC<StandardProps> = () => {
  const classes = useStyles();

  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const { errorMessage, sendData, response: loggedUser } = useMutationApi({
    url: "id/sign_in",
  });

  const { setLoggedUser } = useContext(userContext);

  const handleForm = (event: FormEvent) => {
    event.preventDefault();
    sendData({
      user: {
        email,
        password,
      },
    });
  };

  const {
    login: {
      passwordLabel, emailLabel, buttons, pageTitle, subTitle
    }
  } = useContext(TranslateContext);

  useEffect(() => {
    setLoggedUser(loggedUser);
  }, [ loggedUser, setLoggedUser ])

  return (
    <form onSubmit={handleForm} className={classes.root}>
      <Paper className={classes.paper}>
        {errorMessage && errorMessage.error && (
          <Alert severity="error">{errorMessage.error}</Alert>
        )}
        <div className={classes.margin}>
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <PageTitle title={pageTitle} showBrand={false} />
              <Typography variant='h3' align={'center'} className={classes.subTitle} color={'primary'}>{subTitle.text}</Typography>
            </Grid>
          </Grid>
          
          <Grid container spacing={2} justify={'center'} alignContent={'center'} alignItems={'center'}>
            <Grid item xs={12}>
              <FacebookLogin />
            </Grid>
          </Grid>
          
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12} sm={12}>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={emailLabel}
                type="email"
                fullWidth
                autoFocus
                required
                className={classes.textfield}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                      input: classes.multilineColor
                  }
              }}
                {...getErrorMessage(errorMessage, "email")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12} sm={12}>
              <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={passwordLabel}
                type="password"
                fullWidth
                required
                className={classes.textfield}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                      input: classes.multilineColor
                  }
              }}
                {...getErrorMessage(errorMessage, "password")}
              />
            </Grid>
          </Grid>
          <Grid
            className={classes.actionButtons}
            container
            spacing={2}
            alignItems="center"
            justify="space-between"
          >
            <Grid item xs={12} sm={12} className={classes.button}>
              <Typography variant="subtitle1" color="secondary" component={RouterLink} to="/password-reset" style={{ float: "right", textDecoration: "none"}}>{buttons.forgottenPassword}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.button} style={{ marginTop: "40px" }}>
            <Hidden smDown>
              <Button
                variant="outlined"
                type="submit"
                style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", height: "70px", width: "304px", color: "#FF8A31", fontSize: "20px", letterSpacing: "2px" }}
                fullWidth
              >
                {buttons.login}
              </Button>
              </Hidden>
              <Hidden mdUp>
                <Button
                  variant="outlined"
                  type="submit"
                  style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", height: "70px", color: "#FF8A31", fontSize: "20px", letterSpacing: "2px" }}
                  fullWidth
                >
                  {buttons.login}
                </Button>
              </Hidden>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </form>
  );
};

export default LoginPage;
