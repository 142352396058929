import React, { FormEvent, useCallback, useContext, useEffect } from "react";
import { Button, makeStyles, TextField, } from "@material-ui/core";
import { Theme } from "../../../theme";
import { userContext } from '../../../state/userContext';
import { useMutationApi } from '../../../hooks/api';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    position: "absolute",
    color: palette.common.white,
    backgroundColor: palette.common.white,
    left: 16,
    right: 16,
    bottom: 16,
    display: 'flex',
    borderRadius: 12,
    overflow: 'hidden',
  },
  boardSelect: {
    flex: 1,
    "& input": {
      fontSize: '.8rem',
      fontWeight: 300
    },
    '& .MuiOutlinedInput-input': {
      padding: spacing(1, 3, 1, 2),
      fontSize: '0.8rem',
      textTransform: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: 0
      },
      '&.Mui-focused fieldset': {
        borderWidth: "0px",
      }
    },
  },
  addButton: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    fontSize: '0.9rem',
  },
  createButton: {
    fontSize: '0.9rem',
    width: '100%',
    padding: spacing(2, 0),
  }
}));

type RePinViewProps = {
  pinId: number,
  name: string,
} & StandardProps;

const RePinView: React.FC<RePinViewProps> = ({ className, pinId, name, ...others }) => {
  const classes = useStyles();
  const { loggedUser, boards } = useContext(userContext);
  const [ selectedBoard, setSelectedBoard ] = React.useState('');
  const { sendData } = useMutationApi({
    url: `/api/pins/${pinId}/repins`,
    method: "post",
  });

  useEffect(() => {
    setSelectedBoard(String(boards[0]?.id));
  }, [ boards ]);

  const handleClick = useCallback((event) => {
    event.preventDefault();
  }, [])

  const addToBoard = useCallback((event: FormEvent) => {
    alert("Recept byl přidán.");
    event.preventDefault();
    sendData({
      name,
      board_id: selectedBoard,
    });
  }, [ selectedBoard, name, sendData ])

  const handleChange = useCallback((event) => {
    setSelectedBoard(event.target.value)
  }, [])

  if (!loggedUser) {
    return null;
  }

  return (
    <div className={classes.root}>
      {boards.length > 0 ? (
        <>
          <TextField
            className={classes.boardSelect}
            variant={'outlined'}
            id="standard-select-currency"
            select
            SelectProps={{ native: true }}
            value={selectedBoard}
            onClick={handleClick}
            onChange={handleChange}
          >
            {boards.map((board) => (
              <option key={board.id} value={board.id}>
                {board.name}
              </option>
            ))}
          </TextField>
          <Button
            className={classes.addButton}
            type={'button'}
            variant={'contained'}
            color={'primary'} onClick={addToBoard}>Přidat</Button>
        </>) : (
        <Button
          className={classes.createButton}
          type={'button'}
          variant={'contained'}
          color={'primary'}
          component={RouterLink}
          to={`/add-board`}
          >Založit kuchařku</Button>
      )}
    </div>
  );
};

export default RePinView;
