import React, { useContext } from "react";
import classNames from "classnames";
import { Hidden, Container, makeStyles, Typography } from "@material-ui/core";
import { fontFamilyList, Theme } from "../../../theme";
import TranslateContext from "../../../TranslateContext";
import SearchBlock from "../common/SearchBlock";
import PageTitle from '../pageTitle/pageTitle';
import ItemListView from '../common/ItemListView';
import { ItemVariant } from '../../../globalTypes/AppData';


const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {},
  gridItem: {
    [breakpoints.down("sm")]: {
      paddingTop: 0
    }
  },

  jumbotron: {
    height: "98vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "white",
    backgroundImage: `url(${"/images/homepage/cover.png"})`,
    backgroundPosition: "left center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },

  backgroundCover: {
    overflow: "hidden",
  },

  content: {
    flex: "1 0 auto",
    padding: spacing(12, 3, 2),
    [breakpoints.down("sm")]: {
      padding: spacing(12, 4, 2),
      overflow: 'hidden'
    },
  },

  subTitle: {

  },
  howItWorks: {
    textAlign: "center",
    color: '#FFFFFF',
    fontSize: "20px",
    fontWeight: 100,
    letterSpacing: "2px",
    lineHeight: "30px",
    textTransform: "uppercase",
    fontFamily: fontFamilyList.fahkwang,
  }
}));

const Homepage: React.FC<StandardProps> = ({ className, ...others }) => {
  const classes = useStyles();
  const {
    homePage: {
      title,
      subTitle,
      newestItemsHeaders: { newestRecipes }
    },

  } = useContext(TranslateContext);

  return (
    <div {...others} className={classNames(classes.root, className)}>




          <div className={classes.jumbotron} >

            <PageTitle title={title} />



              <Typography variant='h3' align={'center'} className={classes.subTitle} >{subTitle.text}</Typography>



            <SearchBlock />


            <Hidden smDown>

                <a href="/how-it-works">
                  <div className="" style={{width: "211px", position: "absolute", right: "30px", bottom: "30px"}}>
                    <h2 className={classes.howItWorks}  style={{}}>
                      Jak vařme<br />funguje?
                      <img src="images/about/circle.svg" className="" alt="" style={{marginTop: "-132px"}}/>
                    </h2>
                  </div>
                </a>

            </Hidden>

            <Hidden mdUp>

                <a href="/how-it-works" style={{textDecoration: "none"}}>
                  <div className="" style={{}}>
                    <h2 className={classes.howItWorks}  style={{textAlign: "center"}}>
                      Jak vařme funguje?
                    </h2>
                  </div>
                </a>

            </Hidden>



          </div>

      <Container className={classes.content}>
      <ItemListView
        variant={ItemVariant.Pin}
        title={newestRecipes}
        titleProps={{
          showBrand: false,
          dense: true
        }}
        queryDataUrl={`api/pins?page=1`} />
        </Container>
    </div>
  );
};

export default Homepage;
