import React, { FormEvent, useContext, useState } from "react";
import { Button, makeStyles, MenuItem, Paper, Select, TextField, } from "@material-ui/core";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { pathOr } from "ramda";
import { globalFormTopMargin, Theme } from "../../../theme";
import { useMutationApi } from "../../../hooks/api";
import { getErrorMessage } from "../../common/errorMessage";
import { userContext } from "../../../state/userContext";
import TranslateContext from "../../../TranslateContext";
import { AppData_boardSelect } from "../../../globalTypes/AppData";
import CenteredCircularProgress from '../../common/CenteredCircularProgress';
import DialogTitle from '../common/DialogTitle';

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    marginTop: globalFormTopMargin,
    [breakpoints.down("sm")]: {
      padding: spacing(1, 0, 1, 0),
      marginTop: spacing(0),
      display: "flex"
    }
  },

  cookbookSelect: {
    marginTop: spacing(2),
    [breakpoints.down("sm")]: {
      width: '100%',
    },
  },

  content: {
    padding: spacing(1),
  },

  margin: {
    margin: spacing(2),
    [breakpoints.down("sm")]: {
      margin: spacing(0),
    },
  },

  buttonRow: {
    marginTop: spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& a:first-of-type": {
      marginRight: spacing(1),
    },
    [breakpoints.down("sm")]: {
      flexDirection: 'column-reverse',
      "& a:first-of-type": {
        marginTop: spacing(2),
      },
    },
  },

  actionButtons: {
    textTransform: "none",
    marginTop: spacing(1),
    [breakpoints.down("sm")]: {
      width: '100%'
    }
  },

  paper: {
    margin: "0 auto",
    maxWidth: 500,
    [breakpoints.down("sm")]: {
      boxShadow: 'none',
      maxWidth: "unset",
      width: "100%"
    },
  },
}));

const AddPin: React.FC<StandardProps> = ({ className, ...others }) => {
  const classes = useStyles();
  const { boardId } = useParams();
  const {
    addPin: { title, buttons, pinNameLabel, pinSourceLabel, imgUrlLabel },
  } = useContext(TranslateContext);
  const { loggedUser, boards } = useContext(userContext);
  const [ name, setName ] = useState("");
  const [ source, setSource ] = useState("");
  const [ board, setBoard ] = useState(boardId);
  const [ image_url, setImageUrl ] = useState("");
  const { errorMessage, sendData, response, isLoading } = useMutationApi({
    url: "/api/pins",
    method: "post",
  });
  const history = useHistory();

  const boardSelect: AppData_boardSelect[] = boards;

  if (boardSelect && !board) {
    const firstBoard = pathOr("", [ 0, "id" ], boardSelect);
    if (firstBoard) {
      setBoard(pathOr("", [ 0, "id" ], boardSelect));
    }
  }

  const handleForm = (event: FormEvent) => {
    event.preventDefault();
    sendData({
      name,
      source,
      image_url,
      board_id: board,
    });
  };

  if (response && response.id) {
    history.push(`/${loggedUser?.id}/${response.board.name.toLowerCase()}`);
  }

  return (
    <>
      {isLoading && <CenteredCircularProgress />}
      {!isLoading && (
        <form {...others} onSubmit={handleForm} className={classes.root}>
          <Paper className={classes.paper}>
            <DialogTitle label={title} error={errorMessage} />
            <div className={classes.content}>
              <div className={classes.margin}>
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  label={pinNameLabel}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#000' },
                  }}
                  {...getErrorMessage(errorMessage, "name")}
                />
                <TextField
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                  label={pinSourceLabel}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#000' },
                  }}
                  {...getErrorMessage(errorMessage, "source")}
                />
                <TextField
                  value={image_url}
                  onChange={(e) => setImageUrl(e.target.value)}
                  label={imgUrlLabel}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#000' },
                  }}
                  {...getErrorMessage(errorMessage, "image_url")}
                />
                <Select
                  required
                  variant="outlined"
                  displayEmpty
                  id="search-select-board"
                  className={classes.cookbookSelect}
                  value={board}
                  onChange={(e) => setBoard(e.target.value as string)}
                >
                  {boardSelect.map((boardItem) => (
                    <MenuItem value={boardItem.id} key={boardItem.id}>
                      {boardItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className={classes.buttonRow}>
                <Button
                  variant="outlined"
                  style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", color: "#FF8A31", fontSize: "14px", letterSpacing: "2px" }}
                  component={RouterLink}
                  to={`/${loggedUser?.id}`}
                  className={classes.actionButtons}
                >
                  {buttons.cancel}
                </Button>
                <Button
                  variant="outlined"
                  style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", color: "#FF8A31", fontSize: "14px", letterSpacing: "2px" }}
                  type="submit"
                  className={classes.actionButtons}
                >
                  {buttons.addPin}
                </Button>
              </div>
            </div>
          </Paper>
        </form>
      )}
    </>
  );
};

export default AddPin;
