import React, { useContext } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { Theme } from "../../../theme";
import TranslateContext from "../../../TranslateContext";
import BoardBlock from "../boardBlock/BoardBlock";
import SearchBlock from "../common/SearchBlock";
import ItemListView from '../common/ItemListView';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {},
}));

const MostVisitedBoards: React.FC<StandardProps> = ({ className }) => {
  const classes = useStyles();
  const {
    mostVisitedCookbooks: {
      title
    }
  } = useContext(TranslateContext);

  return (
    <div className={classNames(classes.root, className)}>
      <ItemListView
        queryDataUrl={'api/featured/pins'}
        grid={{ xs: 12, md: 4 }}
        title={title}
      />
      <BoardBlock />
      <SearchBlock />
    </div>
  );
};

export default MostVisitedBoards;
