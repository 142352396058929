import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import ReactMarkdown from "react-markdown/with-html"
import staticTexts from "../../../staticTexts"
import { Theme } from "../../../theme";

const useStyles = makeStyles(({ spacing, typography }: Theme) => ({
  root: {
    fontFamily: typography.fontFamily
  },
}));

const PrivacyPolicy: React.FC<StandardProps> = ({ className, ...others }) => {
  const classes = useStyles();
  const privacyText = staticTexts.privacyPolicy.text;

  return (
    <div {...others} className={classNames(classes.root, className)}>
      <ReactMarkdown source={privacyText} />
    </div>
  );
};

export default PrivacyPolicy;
