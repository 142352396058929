import React from "react";
import { Link, LinkProps } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

type Props = LinkProps & {
  to: string;
};

const LinkPure: React.FC<Props> = ({ children, ...others }) => {
  return (
    <Link component={RouterLink} {...others}>
      {children}
    </Link>
  );
};

export default LinkPure;
