export interface AppData_header {
  logo: AppData_logo;
  menu: AppData_header_menu[];
  addRecipe: string;
  myProfile: string;
  login: string;
  logout: string;
  register: string,
}

export interface AppData_header_menu {
  name: string;
  url: string;
  private: boolean;
}

export interface AppData_homePage {
  title: AppData_pageTitle;
  subTitle: AppData_pageSubTitle;
  newestItemsHeaders: AppData_newestItemsHeaders
}

export interface AppData_boardBlock {
  favoriteCookbooksButton: string;
  selectedCookbooksButton: string;
  aboutButton: string;
  createCookbookButton: string;
}

export interface AppData_filterSelect {
  value: string;
  name: string;
}

export interface AppData_userDetail {
  id: number;
  username: string;
  name: string;
  pins_count: number;
  boards_count: number;
  followers_count: number;
  following_count: number;
  ulr: string;
  avatar_url: string;
  pins_url: string;
  boards_url: string;
  followers_url: string;
  following_url: string
}

export interface AppData_userEdit {
  title: string
  profileChange: {
    title: string;
    yourName: string;
    nickName: string;
    avatar: string;
    noFileSelected: string;
    choseFile: string;
    changePassword: string;
  }
  passwordChange: {
    title: string;
    actualPassword: string;
    newPassword: string;
    repeatPassword: string;
    passwordsNotSame: string;
  }
  buttons: {
    saveChanges: string;
    cancel: string;
    changePassword: string;
  }
}

export interface AppData_searchBlock {
  filterSelect: AppData_filterSelect[]
  searchBar: {
    label: string
  }
}

export interface AppData_share {
  id: number;
  name: string;
  url?: string;
}

export interface AppData_itemList {
  isRecipe: boolean
  logo: AppData_logo;
}

export interface AppData_cookbook {
  id: number;
  name: string;
  url: string;
  slug: string;
}

export interface AppData_user {
  id: number;
  name: string;
  url: string;
  avatar_url: string;
  username:string;
}

export interface AppData_addPin {
  title: string;
  pinNameLabel: string;
  imgUrlLabel: string;
  pinSourceLabel: string;
  buttons: {
    addPin: string;
    cancel: string;
  }
}

export interface AppData_addBoard {
  title: string;
  boardNameLabel: string;
  boardDescription: string;
  buttons: {
    addBoard: string;
    cancel: string;
  }
}

export interface AppData_facebookLogin {
  text: string;
}


export interface AppData_signUp {
  pageTitle: AppData_pageTitle;
  subTitle: AppData_pageTitle;
  emailLabel: string;
  usernameLabel: string;
  passwordLabel: string;
  buttons: {
    register: string;
  }
}

export interface AppData_login {
  pageTitle: AppData_pageTitle;
  subTitle: AppData_pageTitle;
  emailLabel: string;
  passwordLabel: string;
  buttons: {
    login: string;
    forgottenPassword: string;
  }
}

export interface AppData_boardSelect {
  id: number
  name: string
}

export interface AppData_article {
  id: number;
  name: string;
  source: string;
  updated_at?: string;
  created_at?: string;
  board: AppData_cookbook;
  owner: AppData_user;
  repinned?: AppData_share;
  original?: AppData_share;
  comments_count: number;
  url: string;
  image_url: string;
  comments_url: string
}


export interface AppData_selectedCookbook {
  id?: number;
  name: string;
  slug: string
  updated_at?: string;
  created_at?: string;
  owner: AppData_user;
  pins_count: number;
  image_url: string;
  url: string;
}

export interface AppData_cookbookPage {
  addPin: string
}

export interface Board {
  id: number;
  name: string;
  slug: string
  updated_at?: string;
  created_at?: string;
  owner: AppData_user;
  image_url: string;
  pins_count: number;
  url: string;
  followers_count: 0
  followers_url: "https://staging.varme.cz/api/users/14/boards/18/followers"
}


export interface AppData_boardDetail {
  id?: number;
  name: string;
  slug?: string
  updated_at?: string;
  created_at?: string;
  owner: AppData_user;
  image_url: string;
  pins_count: number;
  url: string;
  previous_page_url: string;
  next_page_url: string;
  pins: AppData_article[];
}

export interface AppData_global {
  titleBrand: string;
}

export interface AppData_pageTitle {
  text: string;
}

export interface AppData_pageSubTitle {
  text: string;
}

export interface AppData_newestItemsHeaders {
  newestRecipes: AppData_pageTitle;
  newestCookbooks: AppData_pageTitle;
}

export interface AppData_mostVisitedCookbooks {
  title: AppData_pageTitle
}

export interface AppData_footer {
  email: string;
  copyright: string;
  facebookLink: string;
  instagramLink: string;
  logo: AppData_logo;
}

export interface AppData__site_favicon {
  alt: string;
  format: string;
  size: any;
  title: string;
  url: string;
}

export interface AppData__site {
  favicon: AppData__site_favicon;
}

export interface AppData_logo {
  alt: string;
  url: string;
}

export interface AppData_userProfile {
  addBoard: string;
  editProfile: string
}

export interface AppData_passwordReset {
  title: string;
  subTitle: string;
  emailInputLabel: string;
  buttons: {
    passwordReset: string;
    cancel: string;
  },
  successLabel:string;
  successContent:string;
}

export interface AppData {
  global: AppData_global;
  header: AppData_header;
  homePage: AppData_homePage;
  boardDetail: AppData_cookbookPage;
  userEdit: AppData_userEdit;
  passwordReset: AppData_passwordReset;
  addPin: AppData_addPin;
  addBoard: AppData_addBoard;
  signUp: AppData_signUp;
  facebookLogin: AppData_facebookLogin;
  login: AppData_login;
  boardBlock: AppData_boardBlock;
  searchBlock: AppData_searchBlock;
  itemListView: ItemListView;
  mostVisitedCookbooks: AppData_mostVisitedCookbooks;
  selectedCookbooks: AppData_page;
  userProfile: AppData_userProfile;
  footer: AppData_footer;
  _site: AppData__site;
  boardListView: AppData_page;
  pinListView: AppData_page;
}

interface ItemListView {
  notFound: string,
  showMore: string
}

//todo - apply to all page interfaces
interface AppData_page {
  title: AppData_pageTitle
}

export interface Metric {
  iconPath: string;
  count?: number;
  name: string
}

export enum SearchCategories {
  All = "all",
  Pins = "pins",
  Boards = "boards",
  Users = "users",
}

export enum ItemVariant {
  User = 'user',
  Board = 'board',
  Pin = 'pin',
  Action = 'action',
}
