import React, { FormEvent, useContext, useState } from "react";
import classNames from "classnames";
import { Hidden, IconButton, InputAdornment, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import TranslateContext from "../../../TranslateContext";
import { globalShape, Theme } from "../../../theme";
import SearchIcon from '@material-ui/icons/Search';
import { useHistory, useParams } from 'react-router-dom';
import { AppData_filterSelect, SearchCategories } from '../../../globalTypes/AppData';


const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    padding: spacing(1.5, 0, 3),
    display: "flex",
    justifyContent: "center",
  },

  formContainer: {
    marginTop: "60px",
    width: "50%",
    background: "white",
    [breakpoints.down("sm")]: {
      width: "90%",
    },
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    boxShadow: '2px 2px 22px 6px rgba(0,0,0,0.1)',
    borderRadius: "40px",

    '& .MuiOutlinedInput-root': {
      borderRadius: globalShape,

      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: "0px",

      },
      '& .MuiSelect-select:focus': {
        backgroundColor: "initial"
      }
    },
  },

  filterSelect: {
    color: palette.text.hint,
    minWidth: "150px",
    maxWidth: "200px",
    textAlign: "center",
    fontSize: '1.3rem',
    fontWeight: 300,

    "& :last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderWidth: 0,
    },
  },

  searchBar: {
    width: "100%",
    
    "& :last-child": {
      borderWidth: 0,

    },
    "& input": {
      fontSize: '1.3rem',
      fontWeight: 300,
      fontFamily: "Amiri"
    }
  },

  searchButton: {
    marginRight: spacing(-2)
  },
  menuItem: {
    justifyContent: "center"
  }
}));


const SearchBlock: React.FC<StandardProps> = ({ className, ...others }) => {
  const classes = useStyles();
  const {
    searchBlock: { filterSelect, searchBar }
  } = useContext(TranslateContext);
  const history = useHistory();

  const [ category, setCategory ] = useState(SearchCategories.All);

  const { searchQuery: initialSearchQuery } = useParams();
  const [ searchQuery, setSearchQuery ] = useState(initialSearchQuery || '');

  const handleCategoryChange = (event: any) => {
    setCategory(event.target.value)
  };

  const handleQueryChange = (event: any) => {
    setSearchQuery(event.target.value)
  };

  const handleFormSubmit = (event: FormEvent | MouseEvent) => {
    event.preventDefault();
    if (!searchQuery) {
      return
    }
    history.push(`/search/${category}/${searchQuery}`);
  };

  return (
    <div {...others} className={classNames(classes.root, className)}>
      <form className={classes.formContainer} onSubmit={handleFormSubmit}>
        <Hidden smDown>
          <Select variant="outlined"
                  displayEmpty
                  id="search-select-id"
                  value={category}
                  className={classes.filterSelect}
                  onChange={handleCategoryChange}>
            {filterSelect.map((category: AppData_filterSelect) => (
              <MenuItem className={classes.menuItem} value={category.value} key={category.value}>{category.name}</MenuItem>
            ))}
          </Select>
        </Hidden>

        <TextField id="searchBar" color="secondary" className={classes.searchBar} placeholder={searchBar.label}
                   variant="outlined" onChange={handleQueryChange} value={searchQuery}
                   InputProps={{
                     endAdornment: <InputAdornment position="start">
                       <IconButton onClick={handleFormSubmit} type="submit" className={classes.searchButton}>
                         <SearchIcon
                           color={"primary"}
                           fontSize='large' />
                       </IconButton>
                     </InputAdornment>,
                   }} />
      </form>
    </div>
  );
};

export default SearchBlock;
