import React, { useContext } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { Theme } from "../../../theme";
import Item from "../common/Item";
import SearchBlock from "../common/SearchBlock";
import TranslateContext from "../../../TranslateContext";
import Profile from '../../userView/profile/profile';
import { ItemVariant, Metric } from '../../../globalTypes/AppData';
import ItemsList from '../common/ItemsList';


const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {},
  cookbookTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: spacing(4)
  },
  cookbookImage: {
    width: spacing(10),
    height: spacing(10),
    marginRight: spacing(2)
  },
  titleWithSocPanel: {
    display: "flex",
    flexDirection: "column",
  },
  socPanel: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: 5
  },
  icon: {
    width: spacing(2),
    height: spacing(2),
    paddingLeft: spacing(.5)
  }
}));

type Props = {
  data: any, //todo
  isOwner: boolean,
} & StandardProps;

const BoardDetail: React.FC<Props> = ({
                                           className,
                                           data,
                                           isOwner,
                                           ...others
                                         }) => {
  const classes = useStyles();
  const {
    boardDetail: {
      addPin
    }
  } = useContext(TranslateContext);

  const metrics: Metric[] = [
    { iconPath: '/images/fork-knife.svg', count: data.pins_count, name: 'pins_count' },
  ];

  return (
    <div {...others} className={classNames(classes.root, className)}>
      <Profile
        data={data}
        avatarPath={data.image_url}
        metrics={metrics} />

      <ItemsList
        square
        variant={ItemVariant.Pin}
        items={data?.pins}
        firstItem={isOwner ? (
          <Item
            variant={ItemVariant.Action}
            square action={{ path: `/add-pin/${data.id}`, label: addPin }} />
        ) : null}
      />
      <SearchBlock />
    </div>
  );
};

export default BoardDetail;
