import React from "react";
import classNames from "classnames";
import { Hidden, makeStyles, Container } from "@material-ui/core";
import { fontFamilyList, Theme } from "../../../theme";

const useStyles = makeStyles(({ spacing, typography, breakpoints }: Theme) => ({
  root: {
    fontFamily: typography.fontFamily
  },
  content: {
    flex: "1 0 auto",
    padding: spacing(12, 3, 2),
    [breakpoints.down("sm")]: {
      padding: spacing(12, 4, 2),
      overflow: 'hidden'
    },
  },
  howItWorks: {
    textAlign: "center",
    color: '#FFFFFF',
    fontSize: "20px",
    fontWeight: 100,
    letterSpacing: "2px",
    lineHeight: "30px",
    textTransform: "uppercase",
    fontFamily: fontFamilyList.fahkwang,
  }
}));

const About: React.FC<StandardProps> = ({ className, ...others }) => {
  const classes = useStyles();

  return (
    <div {...others} className={classNames(classes.root, className)}>
      

      <>
        <link href="/about.css" rel="stylesheet" />
        <div id="content">
          <section id="banner-o-varme">
            <div className="container">
              <div className="row col-12 flex-column justify-content-center justify-content-lg-around align-items-end">
                <div className="col-12 col-lg-5 text-center text-lg-start">
                  <h1 className="uppercase-header mb-4">O Vařme.cz</h1>
                  <p className="text-yellow">
                    Aby se dobře žilo, musí se dobře jíst! A proto vzniklo Vařme, aby se vaše oblíbené recepty 
                    neztrácely stejně záhadně, jako například ponožky v pračce. Objevili jste místo, které funguje 
                    jako nástěnka s gastro inspirací z celého světa a vaše osobní digitální kuchařka.
                  </p>
                </div>
                <a href="/how-it-works"style={{textDecoration: "none"}}>
                  <div className="d-none d-lg-block">
                    <h2 className="small-header circle-img-cont" style={{color: "#FFFFFF", textDecoration: "none"}}>
                      Jak vařme<br />funguje?
                      <img src="images/about/circle.svg" className="circle-img" alt=""/>
                    </h2>
                  </div>
                </a>
                <Hidden mdUp>
              
                <a href="/how-it-works" style={{textDecoration: "none"}}>
                  <div className="" style={{}}>
                    <h2 className={classes.howItWorks}  style={{textAlign: "center"}}>
                      Jak vařme funguje?
                    </h2>
                  </div>
                </a>

            </Hidden>
              </div>
            </div>
          </section>
          <Container className={classes.content}>
          <section id="o-varme">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-lg-7">
                  <p>
                    Celou myšlenku podnítila obyčejná zapomnětlivost a nepořádek v receptech. Znáte to 
                    sami – chcete překvapit přátele výbornými miniburgery v domácí housce, nebo hledáte 
                    recept na jahodový koláč, a nedokážete si vzpomenout, kde jste viděli ten nejlahodnější 
                    recept. Chybělo nám místo, kde bychom spolehlivě našli všechny své oblíbené recepty.
                  </p>
                  <p className="text-orange"><i>A tak vzniklo Vařme.cz</i></p>
                </div>
                <div className="col-12 col-lg-5 text-center">
                  <img src="images/about/pancake.png" className="img-fluid" alt=""/>
                </div>
              </div>
            </div>
          </section>
          <section id="zakl-one">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-lg-6 header">
                  <h2 className="uppercase-header text-center">JARDA VÁCHA</h2>
                  <p className="text-orange text-center"><i>spoluzakladatel</i></p>
                  <img src="images/about/chef.png" className="img-fluid d-block d-lg-none mx-auto" alt=""/>
                  <p className="text-center text-lg-start my-5 my-lg-0">
                    Zakladatel značky Konečně vývar. Dvojitý táta. Sportovec, který zdolá i tu nejstrmější horu. 
                    Chlap, co uzvedne půl prasete, nakrmí celou rodinu masem pomalu taženým na vývaru nebo rizotem 
                    z praváků a svojí dvouletou Lauru T-bonem z grilu.
                  </p>
                </div>
                <div className="col-lg-6 d-none d-lg-block">
                  <img src="images/about/chef.png" className="img-fluid" alt=""/>
                </div>
              </div>
            </div>
          </section>


          <section id="zakl-two">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 d-none d-lg-block">
                  <img src="images/about/ondrej.jpg" className="img-fluid" alt=""/>
                </div>
                <div className="col-12 col-lg-6 header">
                  <h2 className="uppercase-header text-center">ONDŘEJ MR. SMITH</h2>
                  <p className="text-orange text-center"><i>spoluzakladatel</i></p>
                  <img src="images/about/ondrej.jpeg" className="img-fluid d-block d-lg-none mx-auto" alt=""/>
                  <p className="text-center text-lg-start my-5 my-lg-0">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Duis risus. Sed vel lectus. 
                    Donec odio tempus molestie, porttitor ut, iaculis quis, sem. Etiam ligula pede, sagittis 
                    quis, interdum ultricies, scelerisque eu. Etiam egestas wisi a erat.
                  </p>
                </div>
              </div>
            </div>
          </section>


          <section id="kontakt">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6 text-center text-lg-start">
                  <h2 className="normal-header">Zůstaňme v kontaktu</h2>
                  <img src="images/about/food.png" className="img-fluid d-block d-lg-none mx-auto" alt=""/>
                  <p>
                    Zajímá vás něco, na co jste na vařme.cz nenašli odpověď? 
                    Řešíte kulinářský problém? Chcete se zapojit a nevíte, jak na to?
                  </p>
                  <p className="text-orange"><i>Dejte nám vědět.</i></p>
                </div>    
                <div className="col-12 col-lg-6">
                  <div className="picture-back">
                    <img src="images/about/food.png" className="img-fluid d-none d-lg-block" alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </section>

          </Container>
        </div>
      </>

    </div>
  );
};

export default About;
