import React, { FormEvent, useContext, useState } from "react";
import { Button, makeStyles, Paper, TextField, Typography, } from "@material-ui/core";
import { globalFormTopMargin, Theme } from "../../../theme";
import { useMutationApi } from "../../../hooks/api";
import { getErrorMessage } from "../../common/errorMessage";
import { userContext } from "../../../state/userContext";
import TranslateContext from "../../../TranslateContext";
import { Link as RouterLink, useHistory } from "react-router-dom";
import DialogTitle from '../../components/common/DialogTitle';
import CenteredCircularProgress from '../../common/CenteredCircularProgress';

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    marginTop: globalFormTopMargin,
    [breakpoints.down("sm")]: {
      padding: spacing(1, 0, 1, 0),
      marginTop: spacing(0),
      display: "flex"
    }
  },

  headerText: {
    padding: spacing(2.5, 0),
    fontWeight: 600,
    textAlign: "center",
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    borderTopRightRadius: spacing(1.5),
    borderTopLeftRadius: spacing(1.5),
    [breakpoints.down("sm")]: {
      backgroundColor: palette.background.default,
      color: palette.primary.main
    },
  },

  content: {
    padding: spacing(1),
  },

  margin: {
    margin: spacing(2),
    [breakpoints.down("sm")]: {
      margin: 0
    },
  },
  buttonRow: {
    marginTop: spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& a:first-of-type": {
      marginRight: spacing(1),
    },
    [breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      "& a:first-of-type": {
        marginTop: spacing(2),
      },
    },
  },

  actionButtons: {
    textTransform: "none",
    margin: spacing(1, 1, 0, 0),
    [breakpoints.down("sm")]: {
      width: '100%'
    },
  },

  alert: {
    borderRadius: 0
  },

  paper: {
    margin: "0 auto",
    maxWidth: 500,
    [breakpoints.down("sm")]: {
      boxShadow: 'none',
      maxWidth: "unset",
      width: "100%"
    },
  },
}));

const UserEdit: React.FC<StandardProps> = ({ className, ...others }) => {
  const classes = useStyles();
  const {
    userEdit: { title, profileChange, buttons },
  } = useContext(TranslateContext);
  const history = useHistory();

  const { loggedUser, setLoggedUser } = useContext(userContext);
  const [ avatar, setAvatar ] = useState();
  const [ name, setName ] = useState(loggedUser?.name);
  const { errorMessage, sendData, response, isLoading } = useMutationApi({
    url: "/id",
    method: "patch",
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  });

  if (response && response.id) {
    setLoggedUser(response);
    history.push(`/${response.id}`);
  }

  const handleForm = (event: FormEvent) => {
    event.preventDefault();
    const formData = new FormData();
    if (avatar) {
      formData.append("user[avatar]", avatar || "");
    }
    formData.append("user[name]", name || "");
    sendData(formData);
  };

  return (
    <>
      {isLoading && <CenteredCircularProgress />}
      {!isLoading && (
        <form {...others} onSubmit={handleForm} className={classes.root}>
          <Paper className={classes.paper}>
            <DialogTitle label={title} error={errorMessage} />
            <div className={classes.content}>
              <div className={classes.margin}>
                <Typography>{profileChange.title}</Typography>
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  label={profileChange.yourName}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#000' },
                  }}
                  {...getErrorMessage(errorMessage, "name")}
                />
                <label htmlFor="btn-upload">
                  <input
                    id="btn-upload"
                    name="btn-upload"
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    // @ts-ignore
                    onChange={(e) => setAvatar(e.target.files[0])} />
                  <Button
                    variant="outlined"
                    component="span"
                    color="primary"
                    className={classes.actionButtons} >
                    {profileChange.avatar}
                  </Button>
                </label>
                <Button
                  variant="outlined"
                  color="primary"
                  component={RouterLink}
                  to={`/change-password`}
                  className={classes.actionButtons}
                >{profileChange.changePassword}</Button>
              </div>
              <div className={classes.buttonRow}>
                <Button
                  variant="outlined"
                  style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", color: "#FF8A31", fontSize: "14px", letterSpacing: "2px" }}
                  component={RouterLink}
                  to={`/${loggedUser?.id}`}
                  className={classes.actionButtons}
                >
                  {buttons.cancel}
                </Button>
                <Button
                  variant="outlined"
                  style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", color: "#FF8A31", fontSize: "14px", letterSpacing: "2px" }}
                  type="submit"
                  className={classes.actionButtons}
                >
                  {buttons.saveChanges}
                </Button>
              </div>
            </div>
          </Paper>
        </form>
      )}
    </>
  );
};

export default UserEdit;
