import React, { useCallback, useContext, useEffect, useState } from "react";
import { useQueryApi } from "../../../hooks/api";
import ItemsList, { ItemsListProps } from "./ItemsList";
import PageTitle, { PageTitleProps } from '../pageTitle/pageTitle';
import { AppData_pageTitle, ItemVariant } from '../../../globalTypes/AppData';
import { Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { Theme } from '../../../theme';
import { Link as RouterLink } from "react-router-dom";
import { ItemProps } from './Item';
import TranslateContext from '../../../TranslateContext';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { match, replace } from 'ramda';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  showAllWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: spacing(2, 0, 0),
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    margin: spacing(2, 0),
  },
  showMoreBtn: {
    textTransform: "uppercase",
    borderColor: "black",
    borderRadius: "40px",
    color: "rgb(255, 138, 49)",
    fontSize: "14px",
    letterSpacing: "2px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    padding: "5px 15px",
    backgroundColor: "white",
    boxShadow: "none",
  },
}));

type Props = {
  isRecipe?: boolean;
  queryDataUrl: string;
  showAllUrl?: string;
  title?: AppData_pageTitle;
  limit?: number; //only for search result use - not supported by API
  titleProps?: Partial<PageTitleProps>
} & Partial<ItemProps> & Partial<ItemsListProps> & StandardProps;

const ItemListView: React.FC<Props> = ({
                                         className,
                                         variant = ItemVariant.Pin,
                                         queryDataUrl: queryDataUrlDefault,
                                         title,
                                         titleProps,
                                         limit,
                                         showAllUrl,
                                         ...others
                                       }) => {
  const classes = useStyles();
  const [ items, setItems ] = useState(null) as any;
  const [ showAll, setShowAll ] = useState(null) as any;
  const [ queryDataUrl, setQueryDataUrl ] = useState(queryDataUrlDefault);
  const [ previousUrl, setPreviousUrl ] = useState(null);
  const { data, isLoading } = useQueryApi({ url: queryDataUrl });

  const resetState = useCallback(() => {
    setItems(null);
    setShowAll(null);
    setPreviousUrl(null);
  }, [ setItems, setShowAll, setPreviousUrl ])

  useEffect(() => {
    setQueryDataUrl(queryDataUrlDefault);
    resetState();
  }, [ queryDataUrlDefault, setQueryDataUrl, resetState ]);

  useEffect(() => {
    if (data && limit) {
      const transformedItems = variant === ItemVariant.Pin ? data.data : data;
      if (transformedItems?.length > limit) {
        setShowAll(true);
        setItems(transformedItems.slice(0, limit));
      } else {
        setShowAll(false);
        setItems(transformedItems)
      }
    }
  }, [ data, limit, setShowAll, setItems, variant ]);

  useEffect(() => {
    if (data && !limit) {
      if (variant === ItemVariant.Pin) {
        //pagination
        if (!items) {
          setItems(data.data);
          setPreviousUrl(data.previous_page_url)
        } else {
          if (data.previous_page_url !== previousUrl) {
            setPreviousUrl(data.previous_page_url);
            setItems([ ...items, ...data.data ]);
          }
        }
      } else {
        setItems(data);
      }
    }
  }, [ data, items, limit, setItems, variant, previousUrl ]);


  const { itemListView: texts } = useContext(TranslateContext);

  const fetchMoreData = () => {
    if (data && data.next_page_url) {
      let nextPage = getPageFromUrl(data.next_page_url);
      setQueryDataUrl(replace(/page=[0-9]*/, `page=${nextPage}`, queryDataUrl));
    }
  };

  return (
    <div className={classNames(className)}>
      {title && <PageTitle title={title} {...titleProps} />}

      {items && Array.isArray(items) && (
        <InfiniteScroll
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={true}
          loader=""
          style={{ overflow: 'hidden' }}>
          <ItemsList
            items={items}
            variant={variant}
            {...others}
          />
        </InfiniteScroll>)}

      {!isLoading && items && Array.isArray(items) && items.length === 0 && (
        <Typography>{texts.notFound}</Typography>
      )}

      {isLoading && (
        <div className={classes.loadingWrapper}>
          <CircularProgress color="primary" />
        </div>
      )}

      {limit && showAll && Boolean(items?.length) && showAllUrl && (
        <div className={classes.showAllWrapper}>
          <Button className={classes.showMoreBtn} variant="contained"
                  color={'primary'}
                  component={RouterLink}
                  to={showAllUrl}>{texts.showMore}</Button>
        </div>
      )}

    </div>
  )
};

function getPageFromUrl(urlWithPage: string) {
  const pageQuery = match(/page=[0-9]*/, urlWithPage);
  if (pageQuery && pageQuery[0]) {
    const result = match(/[0-9]+/, pageQuery[0]) as any;
    return result[0]
  }
  return 0
}

export default ItemListView;
