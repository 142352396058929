import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { Avatar, ButtonBase, makeStyles, Typography } from "@material-ui/core";
import { fontFamilyList, globalShape, Theme } from "../../../theme";
import { AppData_article, AppData_selectedCookbook, AppData_userDetail, ItemVariant } from "../../../globalTypes/AppData";
import { Link as RouterLink } from "react-router-dom";
import Profile from '../../userView/profile/profile';
import { getMetrics } from "../../userView/userProfile/UserProfile";
import RePinView from '../rePinView/rePinView';

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  square: {
    height: 230,
  },
  autoHeight: {
    height: 'auto',
  },

  articleButton: {
    position: "relative",
    flex: '1 1 auto',
    minHeight: 0,
    maxHeight: '80vh',
    overflow: 'hidden',
    borderRadius: globalShape,
    textTransform: "uppercase",
    overFlow: "hidden",
    '&:before': {
      borderRadius: globalShape,
      content: "''",
      display: 'block',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      opacity: "15%",
      background: palette.common.black,
    }
  },
  recipe: {},
  recipeButtonImage: {
    height: '100%',
  },
  recipeButtonTitle: {
    margin: spacing(1),
    top: 0,
  },
  authorRecipe: {
    fontFamily: fontFamilyList.amiri,
    fontWeight: 300,
    textAlign: "left",
    color: palette.primary.main,
    width: "100%",
  },

  nameRecipe: {
    fontWeight: 300,
    textAlign: "left",
    alignSelf: "flex-end",
    width: "100%",
    color: "#1A1D23",
    paddingBottom: "2px",
  },

  noImage: {
    backgroundColor: palette.primary.main,
  },
  noShadow: {
    textShadow: 'none'
  },

  boardButtonImage: {
    minHeight: '100%',
  },

  buttonTitle: {
    position: "absolute",
    textAlign: "center",
    color: palette.common.white,
  },
  articleButtonTitle: {},
  socialPanel: {
    margin: "23px 0px 4px 0px",
    display: "flex",
    height: "100%",
  },
  authorButton: {
    textTransform: "unset",
    alignSelf: "flex-start",
    width: "100%",
    paddingTop: "2px",
  },
  authorButtonImage: {
    marginRight: spacing(1),
    float: "left",
    alignSelf: "center"
  },
  textContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  cookbook: {
    color: palette.primary.main,
    textTransform: "unset",
  },
  cookbookButtonImage: {
    width: spacing(3),
    height: spacing(3),
    paddingLeft: spacing(1)
  },
  authorCookBook: {
    fontFamily: fontFamilyList.amiri,
    fontWeight: 300,
    textAlign: "left",
    color: palette.primary.main,
    width: "100%",
  },
  pinCount: {
    fontFamily: fontFamilyList.sourceSansPro,
    fontWeight: 400
  },
  pinCountLabel: {
    fontSize: '.8rem',
    fontWeight: 300,
    textAlign: "left",
    alignSelf: "flex-end",
    width: "100%",
    color: "#1A1D23",
    paddingBottom: "2px",
  },
  pinCountImage: {
    width: spacing(2),
    height: spacing(2),
    paddingLeft: spacing(.5),
    alignSelf: "flex-end",
  },

  boardName: {
    display: "flex",
  },
  repin: {
    display: 'none'
  },
  showRepin: {
    display: 'block'
  }
}));

export type ItemProps = {
  variant?: ItemVariant;
  data?: (AppData_article | AppData_selectedCookbook | AppData_userDetail);
  action?: {
    path: string
    label: string;
  };
  autoHeight?: boolean;
  square?: boolean;
} & StandardProps;

const Item: React.FC<ItemProps> = ({
                                     variant = ItemVariant.Pin,
                                     className,
                                     action,
                                     autoHeight = false,
                                     square = false,
                                     data,
                                     ...others
                                   }) => {
  const classes = useStyles();

  const [ hover, setHover ] = useState(false);

  const isPin = useMemo(() => variant === ItemVariant.Pin, [ variant ]);

  return (
    <div {...others} className={classNames(
      classes.root,
      className, {
        [classes.recipe]: isPin,
        [classes.autoHeight]: autoHeight
      })}>

      {variant === ItemVariant.Action && !data && action && (
        <>
          <ButtonBase
            color="secondary"
            component={RouterLink}
            to={action.path}
            className={classNames(classes.noImage, classes.articleButton)}>
            <Typography variant="h6"
                        className={classNames(classes.noShadow, classes.buttonTitle)}>
              {action.label}
            </Typography>
          </ButtonBase>
          <div className={classes.socialPanel} />
        </>
      )}

      {data && action && console.warn('wrong combination')}

      {variant === ItemVariant.Pin && data && 'board' in data && (
        <>
          <ButtonBase
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            color="secondary" target="_blank" href={data.source}
            className={classNames({
              [classes.articleButton]: true,
              [classes.noImage]: !data.image_url,
            })}>
            {data.image_url && (
                <img alt="This is illustration" src={data.image_url.replace('small_', '')}
                     className={classes.recipeButtonImage} />
            )}

            <div className={classNames(classes.repin,{[classes.showRepin]: hover})}>
              <RePinView pinId={data.id} name={data.name} />
            </div>
          </ButtonBase>

          <div className={classes.socialPanel}>
            <Avatar alt="This is author illustration" src={data.owner.avatar_url} className={classes.authorButtonImage} />
            <div className={classes.textContainer}>
              <Typography className={classes.nameRecipe}>{data.name}</Typography>
              <ButtonBase className={classes.authorButton} component={RouterLink} to={`/${data.owner.username}`}>
                <Typography className={classNames({
                  [classes.authorRecipe]: isPin,
                  [classes.authorCookBook]: !isPin
                })}>{data.owner.name}</Typography>
              </ButtonBase>
            </div>
          </div>
        </>
      )}

      {variant === ItemVariant.Board && data && 'slug' in data && (
        <>
          <ButtonBase color="secondary" component={RouterLink} to={`/${data.owner.username}/${data.slug}`}
                      className={classNames({
                        [classes.articleButton]: true,
                        [classes.noImage]: !data.image_url
                      })}>
            {data.image_url && (
              <>
                <img alt="This is illustration" src={data.image_url.replace('small_', '')} //replace is not optimal
                     className={classes.boardButtonImage} />
              </>)}

            {!data.image_url && (
              <>
                <img alt="This is illustration" src="/images/default_image.jpg"
                     className={classes.boardButtonImage} />
              </>
            )}
            <Typography variant="h6"
                        style={{ fontWeight: "bold" }}
                        className={classNames(
                          classes.buttonTitle,
                          classes.articleButtonTitle, {
                            [classes.noShadow]: !data.image_url
                          })}>{data.name}
            </Typography>
          </ButtonBase>


          <div className={classes.socialPanel}>

            <Avatar alt="This is author illustration" src={data.owner.avatar_url} className={classes.authorButtonImage} />

            <div className={classes.textContainer}>


              <ButtonBase className={classes.pinCount} component={RouterLink} to={`/${data.owner.username}/${data.slug}`}>
                <Typography className={classes.nameRecipe}>{data.name} | </Typography>
                <div className={classes.boardName}>
                  <Typography className={classes.pinCountLabel}> &nbsp; {data.pins_count}</Typography>
                  <img alt="This is cookbook illustration" src="/images/fork-knife.svg" className={classes.pinCountImage} />
                </div>
              </ButtonBase>


              <ButtonBase className={classes.authorButton} component={RouterLink} to={`/${data.owner.username}`}>
                <Typography className={classNames({
                  [classes.authorRecipe]: isPin,
                  [classes.authorCookBook]: !isPin
                })}>{data.owner.name}</Typography>
              </ButtonBase>
            </div>
          </div>
        </>
      )}

      {variant === ItemVariant.User && data && 'avatar_url' in data && (
        <ButtonBase className={classes.articleButton} component={RouterLink} to={`/${data.id}`}>
          <Profile asItem data={data} metrics={getMetrics(data)} avatarPath={data.avatar_url} />
        </ButtonBase>
      )}
    </div>
  );
};

export default Item;
