import { createMuiTheme, responsiveFontSizes, Theme as ThemeDefault } from '@material-ui/core/styles';
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

export const fontFamilyList = {
  amiri: "'Amiri'",
  fahkwang: "'Fahkwang'",
  bebasNeue: "'Bebas Neue'",
  sourceSansPro: "'Source Sans Pro'",
  openSans: "'Open Sans'",
};

const fontFamily = fontFamilyList.fahkwang + ",Helvetica,Arial,sans-serif, sans-serif";
const breakpoints = createBreakpoints({});

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FF8A31',
      light: "#FFFFFF",
      dark: "#F7F7F7"
    },

    text: {
      primary: '#000000',
      secondary: '#FF8A31',
    },

    background: {
      default: '#FFFFFF'

    },

    action: {
      disabledBackground: '#D8D8D8'
    },

    common: {
      white: '#FFFFFF'
    },

    secondary: {
      main: '#171717'
    },
  },

  typography: {
    fontFamily: fontFamily,
    button: {
      fontFamily: fontFamilyList.fahkwang,
    },
    h3: {
      //used for page title
      fontSize: 30,
      fontFamily: fontFamilyList.fahkwang,
    },
    h6: {
      //used for item(recipe and board) titles
      fontSize: '1.5rem',
      lineHeight: 1.2,
      fontFamily: fontFamilyList.fahkwang,
      [breakpoints.up("xs")]: {  //from override defaults
        fontSize: '1.5rem'
      }
    },
    subtitle1: {

    },
    body1: {
      lineHeight: 1
    }
  },

  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "#FFFFFF",
        fontWeight: 600,
        fontSize: 21,
        lineHeight: 1,
        '&:hover': {
          backgroundColor: '#616161'
        },
        [breakpoints.down("md")]: {
          fontSize: 20
        },
        [breakpoints.down("sm")]: {
          lineHeight: 1.5,
        },
      },
      containedSecondary: {
        color: '#FC7526',
        backgroundColor: "#FFFFFF",
        fontWeight: 600,
        fontSize: 21,
        lineHeight: 1,
        [breakpoints.down("md")]: {
          fontSize: 20
        },
        [breakpoints.down("sm")]: {
          lineHeight: 1.5,
        },
      },
      root: {
        textTransform: "unset",
      }
    },
    MuiButtonBase: {
      root: {
        textTransform: "unset",
      }
    },
    MuiIconButton: {
      root:{
        color: "white",
      }
    },
    MuiFormControl:{
      root:{
        margin: "4px 0"
      }
    }
  },

  shape: { borderRadius: 12 }

});

theme = responsiveFontSizes(theme);

export default theme;

export type Theme = typeof theme & ThemeDefault;
export const globalShape = "17.5px";
export const globalFormTopMargin = "24px";
