import React, { useContext, useEffect } from "react";
import classNames from "classnames";
import { IconButton, makeStyles, MenuItem } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { userContext } from "../../../state/userContext";
import TranslateContext from "../../../TranslateContext";
import { useMutationApi } from "../../../hooks/api";

const useStyles = makeStyles(() => ({
  root: {},
}));

type LogoutButtonProps = {
  menuItem?: boolean;
  handleClose?: () => any;
} & StandardProps

const LogoutButton: React.FC<LogoutButtonProps> = ({
                                                     className,
                                                     menuItem,
                                                     handleClose,
                                                     ...others
                                                   }) => {
  const classes = useStyles();
  const { setLoggedUser } = useContext(userContext);
  const {
    header: { logout },
  } = useContext(TranslateContext);
  const { sendData, response } = useMutationApi({
    url: "id/sign_out",
    method: "DELETE"
  });

  useEffect(() => {
    if (response !== undefined) {
      setLoggedUser(undefined);
    }
  }, [response, setLoggedUser])

  const handleLogout = () => {
    sendData({});
  };

  return menuItem ? (
    <MenuItem onClick={handleClose} style={{marginLeft: "auto"}} component={RouterLink} to={"/login"}>{logout}</MenuItem>
  ) : (
    <IconButton
      {...others}
      className={classNames(className, classes.root)}
      component={RouterLink}
      color="primary"
      to={"/login"}
      title={logout}
      style={{marginLeft: "auto"}}
      onClick={handleLogout}
    >
      <PowerSettingsNewIcon />
    </IconButton>
  );
};

export default LogoutButton;
