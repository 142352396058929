import React from "react";
import { User } from "../globalTypes/User";
import { Board } from '../globalTypes/AppData';

export interface UserContext {
  loggedUser: User | null | undefined;
  boards: Board[];
  setLoggedUser: (user: User | undefined) => void;
}

export const defaultUser = {
  loggedUser: undefined,
  boards: [],
  setLoggedUser: () => {
  },
};

export const userContext = React.createContext<UserContext>(defaultUser);

