import React, { useContext } from "react";
import classNames from "classnames";
import { Button, Hidden, makeStyles, Typography } from "@material-ui/core";
import { fontFamilyList, globalShape, Theme } from "../../../theme";
import TranslateContext from "../../../TranslateContext";
import { Link as RouterLink } from "react-router-dom";
import { userContext } from "../../../state/userContext";
import ButtonBase from '@material-ui/core/ButtonBase';


const useStyles = makeStyles(({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    display: "flex",
    margin: spacing(2, 0, 1),
    justifyContent: "space-between",
    flexDirection: 'column',
    [breakpoints.up("md")]: {
      flexDirection: 'row',
    },
  },

  middleButtonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [breakpoints.down("sm")]: {
      width: '100%',
      flexDirection: "row",
      order: 3,
      margin: spacing(1, 0, 0),
    }
  },

  middleButton: {
    lineHeight: 1.5,
    '&:first-of-type': {
      boxShadow: "3px 3px 23px 6px rgba(0,0,0,0.1)"
    },
    [breakpoints.down("sm")]: {
      flex: '1 1 auto',
      '&:first-of-type': {
        marginRight: spacing(3),
      }
    },
    [breakpoints.down("xs")]: {
      fontSize: 13,
    }
  },

  favoriteCookbooksButton: {
    boxShadow: 'none',
    backgroundImage: "url('/images/homepage/favorite-cookbook.jpg')",
    backgroundSize: "cover",
    minWidth: "40%",
    backgroundPosition: "0 50%",
    borderRadius: globalShape,
    textTransform: "uppercase",
    fontFamily: fontFamilyList.bebasNeue,
    height: 120,
    '&:before': {
      borderRadius: globalShape,
      content: "''",
      display: 'block',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      opacity: "15%",
      background: palette.common.black,
    },
    [breakpoints.down("sm")]: {
      order: 2,
      margin: spacing(1, 0),
    },
  },

  selectedCookbooksButton: {
    boxShadow: 'none',
    backgroundImage: "url('/images/homepage/selected-cookbook.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "0 19%",
    minWidth: "40%",
    borderRadius: globalShape,
    textTransform: "uppercase",
    fontFamily: fontFamilyList.bebasNeue,
    height: 120,
    '&:before': {
      borderRadius: globalShape,
      content: "''",
      display: 'block',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      opacity: "15%",
      background: palette.common.black,
    },
    [breakpoints.down("sm")]: {
      order: 1,
      margin: spacing(1, 0)
    }
  },
  featuredButtonTitle: {
    color: '#FFFFFF',
    position: 'absolute',
    texAlign: 'center',
  }
}));

type BoardBlockProps = {
  showOnMobile?: boolean;
} & StandardProps

const BoardBlock: React.FC<BoardBlockProps> = ({
                                                         className,
                                                         showOnMobile = false,
                                                         ...others
                                                       }) => {
  const classes = useStyles();
  const { loggedUser } = useContext(userContext);
  const {
    boardBlock: {
      aboutButton, createCookbookButton, favoriteCookbooksButton, selectedCookbooksButton
    }
  } = useContext(TranslateContext);

  return (
    <div {...others} className={classNames(classes.root, className)}>
      <Hidden xsDown={!showOnMobile}>
        <ButtonBase color="primary" component={RouterLink}
                    className={classes.favoriteCookbooksButton}
                    to={"/most-visited-cookbooks"}>
          <Typography variant="h6" className={classes.featuredButtonTitle}>{favoriteCookbooksButton}</Typography>
        </ButtonBase>
      </Hidden>

      <div className={classes.middleButtonContainer}>
        <Button variant="contained"
                color="secondary"
                className={classes.middleButton}
                component={RouterLink} to={"/about"}>{aboutButton}</Button>
        <Button variant="contained"
                color="primary"
                className={classes.middleButton}
                component={RouterLink} to={loggedUser ? "/add-board" : "/sign-up"}>{createCookbookButton}</Button>
      </div>

      <Hidden xsDown={!showOnMobile}>
        <ButtonBase color="primary"
                    className={classes.selectedCookbooksButton} component={RouterLink}
                    to={"/selected-cookbooks"}>
          <Typography variant="h6" className={classes.featuredButtonTitle}>{selectedCookbooksButton}</Typography>
        </ButtonBase>
      </Hidden>
    </div>
  );
};

export default BoardBlock;
