import React, { useContext } from 'react';
import { Hidden, makeStyles, Typography } from "@material-ui/core";
import Link from "../../common/Link";
import classNames from "classnames";
import SmartLink from "../routing/SmartLink";
import TranslateContext from "../../../TranslateContext";
import { Theme } from "../../../theme";

const useStyles = makeStyles(({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    display: "flex",
    backgroundColor: "white",
    justifyContent: "space-between",
    padding: spacing(2),
    alignItems: "center",
    color: palette.text.primary,
    fontSize: "1rem",
    [breakpoints.down("sm")]: {
      flexDirection: 'column',
    },
  },

  copyrightContainer: {
    color: "black",
    fontFamily: "'Amiri' ,'Source Sans Pro', sans-serif;"
  },

  headerIcon: {
    padding: spacing(0, 2),
    fontSize: "2rem",
    color: "black",
    width: "30px"
  },

  link: {
    textDecoration: "none",
    color: "black",
    paddingLeft: spacing(2),
    fontFamily: "'Amiri' ,'Source Sans Pro', sans-serif;"
  },

  footerMenuItems: {
    display: "flex",
    flex: 1
  },

  logo: { marginBottom: spacing(1.5) }

}));

type Props = StandardProps;

const Footer: React.FC<Props> = ({ ...others }) => {
  const classes = useStyles({});

  const {
    footer: {
      email,
      copyright,
      facebookLink,
      instagramLink,
      logo
    }
  } = useContext(TranslateContext);

  return (
    <div {...others} className={classNames(classes.root, others.className)}>

      <Hidden mdUp>
        <Link to={"/"} className={classes.logo} style={{display: "none"}}>
          <img src={logo.url} alt={logo.alt} />
        </Link>
      </Hidden>

      <Hidden smDown>
        <div className={classes.footerMenuItems} style={{display: "none"}}>
          <Link underline="none" className={classes.link} to={"/terms"}>Všeobecné obchodní podmínky</Link>
          <Link underline="none" className={classes.link} to={"/privacy"}>Ochrana osobních údajů</Link>
        </div>

        <Typography className={classes.copyrightContainer} style={{margin: "0 auto"}}>
          {copyright}
        </Typography>
        
      </Hidden>

      <div style={{display: "none"}}>
        <SmartLink href={email}>
          <img alt="This is email illustration" src="/images/email.svg"
               className={classes.headerIcon} />
        </SmartLink>
        <SmartLink href={instagramLink}>
          <img alt="This is instagram illustration" src="/images/instagram.svg"
               className={classes.headerIcon} />
        </SmartLink>
        <SmartLink href={facebookLink}>
          <img alt="This is facebook illustration" src="/images/facebook.svg"
               className={classes.headerIcon} />
        </SmartLink>
      </div>
      <Hidden mdUp>
        <Typography className={classes.copyrightContainer}>
          {copyright}
        </Typography>
      </Hidden>
    </div>
  );
};

export default Footer;
