import React from "react";
import { useParams } from "react-router-dom";
import { lensPath, set } from "ramda";
import ChangePassword, { DataProps } from "./ChangePassword";
import { useMutationApi } from "../../../hooks/api";

const ChangePasswordWithToken: React.FC<StandardProps> = ({ className, ...others }) => {
  const { token } = useParams();

  const { errorMessage, sendData, response, isLoading } = useMutationApi({
    url: "/id/password",
    method: "put",
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  });

  const submitForm = (data: DataProps) => {
    sendData(set(lensPath(["user", "reset_password_token"]), token, data));
  };

  return <ChangePassword
    className={className}
    errorMessage={errorMessage}
    sendData={submitForm}
    response={response}
    isLoading={isLoading}
    {...others} />;
};

export default ChangePasswordWithToken;
