import React, { FormEvent, useContext, useState } from "react";
import { Button, makeStyles, Paper, TextField } from "@material-ui/core";
import { globalFormTopMargin, Theme } from "../../../theme";
import { useMutationApi } from "../../../hooks/api";
import { getErrorMessage } from "../../common/errorMessage";
import { userContext } from "../../../state/userContext";
import TranslateContext from "../../../TranslateContext";
import { Link as RouterLink, useHistory } from "react-router-dom";
import DialogTitle from '../common/DialogTitle';
import CenteredCircularProgress from '../../common/CenteredCircularProgress';

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    marginTop: globalFormTopMargin,
    [breakpoints.down("sm")]: {
      padding: spacing(1, 0, 1, 0),
      marginTop: spacing(0),
      display: "flex"
    }
  },

  content: {
    padding: spacing(1),
  },

  margin: {
    margin: spacing(2),
    [breakpoints.down("sm")]: {
      margin: spacing(0),
    },
  },

  buttonRow: {
    marginTop: spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& a:first-of-type": {
      marginRight: spacing(1)
    },
    [breakpoints.down("sm")]: {
      flexDirection: 'column-reverse',
      "& a:first-of-type": {
        marginTop: spacing(2),
      },
    },
  },

  actionButtons: {
    textTransform: "none",
    marginTop: spacing(1),
    [breakpoints.down("sm")]: {
      width: '100%'
    }
  },

  paper: {
    margin: "0 auto",
    maxWidth: 500,
    [breakpoints.down("sm")]: {
      boxShadow: 'none',
      maxWidth: "unset",
      width: "100%"
    },
  },
}));

const AddBoard: React.FC<StandardProps> = ({ className, ...others }) => {
  const classes = useStyles();
  const {
    addBoard: {
      title, buttons, boardDescription, boardNameLabel
    }
  } = useContext(TranslateContext);

  const { loggedUser } = useContext(userContext);
  const [ name, setName ] = useState("");
  const [ description, setDescription ] = useState("");

  const { sendData, isLoading, errorMessage, response } = useMutationApi({
    url: `/api/users/${loggedUser?.id}/boards`,
    method: "post"
  });

  const history = useHistory();
  const handleForm = (event: FormEvent) => {
    event.preventDefault();
    sendData({
      name,
      description,
    });
  };

  if (response && response.id) {
    history.push(`/${loggedUser?.id}/${response.id}`);
  }

  return (
    <>
      {isLoading && <CenteredCircularProgress />}
      {!isLoading && (
        <form {...others} onSubmit={handleForm} className={classes.root}>
          <Paper className={classes.paper}>
            <DialogTitle label={title} error={errorMessage} />
            <div className={classes.content}>
              <div className={classes.margin}>
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  label={boardNameLabel}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#000' },
                  }}
                  {...getErrorMessage(errorMessage, "name")}
                />
                <TextField
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  label={boardDescription}
                  fullWidth
                  InputLabelProps={{
                    style: { color: '#000' },
                  }}
                  {...getErrorMessage(errorMessage, "description")}
                />
              </div>

              <div className={classes.buttonRow}>
                <Button
                  variant="outlined"
                  style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", color: "#FF8A31", fontSize: "14px", letterSpacing: "2px" }}
                  component={RouterLink}
                  to={`/${loggedUser?.id}`}
                  className={classes.actionButtons}
                >
                  {buttons.cancel}
                </Button>
                <Button
                  variant="outlined"
                  style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", color: "#FF8A31", fontSize: "14px", letterSpacing: "2px" }}
                  type="submit"
                  className={classes.actionButtons}
                >
                  {buttons.addBoard}
                </Button>
              </div>
            </div>
          </Paper>
        </form>
      )}
    </>
  );
};

export default AddBoard;
