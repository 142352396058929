import { Container, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Footer from "./app/components/common/Footer";
import Header from "./app/components/common/Header";
import PrivateRoute from "./app/common/ProtectedRoute";
import LoginView from "./app/loginView/LoginView";
import Homepage from "./app/components/homepage/Homepage";
import Terms from "./app/components/terms/Terms";
import About from "./app/components/about/About";
import How from "./app/components/how/How";
import Fork from "./app/components/fork/Fork";
import SignUpView from "./app/signUpView/SignUpView";
import SelectedBoards from "./app/components/selectedBoards/SelectedBoards";
import MostVisitedBoards from "./app/components/mostVisitedBoards/MostVisitedBoards";
import PrivacyPolicy from "./app/components/privacyPolicy/PrivacyPolicy";
import UserProfile from "./app/userView/userProfile/UserProfile";
import UserEdit from "./app/userView/userEdit/UserEdit";
import AddPin from "./app/components/addPin/AddPin";
import AddBoard from "./app/components/addBoard/AddBoard";
import PasswordReset from "./app/userView/passwordReset/PasswordReset";
import BoardDetailView from "./app/components/boardDetail/BoardDetailView";
import SearchView from './app/searchView/searchView';
import PinBoardView from './app/pinBoardView/pinBoardView';
import { ItemVariant } from './globalTypes/AppData';
import ChangePasswordView from './app/userView/changePassword/ChangePasswordView';
import ChangePasswordWithToken from './app/userView/changePassword/ChangePasswordWithToken';

const useStyles = makeStyles(({ palette, spacing, breakpoints }: Theme) => ({
  "@global": {
    body: {
      background: palette.background.default,
    },
  },
  layout: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    flex: "1 0 auto",
    padding: spacing(12, 3, 2),
    [breakpoints.down("sm")]: {
      padding: spacing(12, 4, 2),
      overflow: 'hidden'
    },
  },
  noShrink: {
    flexShrink: 0,
  },
}));

function Router() {
  const classes = useStyles();

  return (
    <BrowserRouter>
      <div className={classes.layout}>
        <Header className={classes.noShrink} />

        <Switch>
          <Route path="/login">
            <Container className={classes.content}>
              <LoginView />
            </Container>
          </Route>
          <Route path="/about">
            <About />
          </Route>

          <Route path="/how-it-works">
            <How />
          </Route>

          <Route path="/paratko">
            <Fork />
          </Route>
          <Route path="/terms">
            <Container className={classes.content}>
              <Terms />
            </Container>
          </Route>
          <Route path="/sign-up">
            <Container className={classes.content}>
              <SignUpView />
            </Container>
          </Route>
          <Route path="/password-reset">
            <Container className={classes.content}>
              <PasswordReset />
            </Container>
          </Route>
          <Route path="/change-password/:token">
            <Container className={classes.content}>
              <ChangePasswordWithToken />
            </Container>
          </Route>
          <Route path="/privacy">
            <Container className={classes.content}>
              <PrivacyPolicy />
            </Container>
          </Route>
          <Route path="/selected-cookbooks">
            <Container className={classes.content}>
              <SelectedBoards />
            </Container>
          </Route>
          <Route path="/most-visited-cookbooks">
            <Container className={classes.content}>
              <MostVisitedBoards />
            </Container>
          </Route>
          <Route path={"/boards"}>
            <Container className={classes.content}>
              <PinBoardView variant={ItemVariant.Board} />
            </Container>
          </Route>
          <Route path="/pins/:page?">
            <Container className={classes.content}>
              <PinBoardView variant={ItemVariant.Pin} />
            </Container>
          </Route>
          <PrivateRoute path="/user-edit">
            <Container className={classes.content}>
              <UserEdit />
            </Container>
          </PrivateRoute>
          <PrivateRoute path="/change-password">
            <Container className={classes.content}>
              <ChangePasswordView />
            </Container>
          </PrivateRoute>
          <PrivateRoute path="/add-pin/:boardId?">
            <Container className={classes.content}>
              <AddPin />
            </Container>
          </PrivateRoute>
          <PrivateRoute path="/add-board">
            <Container className={classes.content}>
              <AddBoard />
            </Container>
          </PrivateRoute>
          <Route path="/search/:category/:searchQuery">
            <Container className={classes.content}>
              <SearchView />
            </Container>
          </Route>
          <Route path="/search">
            <Container className={classes.content}>
              <SearchView />
            </Container>
          </Route>
          <Route path="/:username/:slug">
            <Container className={classes.content}>
              <BoardDetailView />
            </Container>
          </Route>
          <Route path="/:username">
            <Container className={classes.content}>
              <UserProfile />
            </Container>
          </Route>
          <Route path="/">
            <Homepage />
          </Route>
        </Switch>
        <Footer className={classes.noShrink} />
      </div>
    </BrowserRouter>
  );
}

export default Router;
