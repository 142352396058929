import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Theme } from "../../../theme";
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    padding: spacing(2.5, 0),
    fontWeight: 600,
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    borderTopRightRadius: spacing(1.5),
    borderTopLeftRadius: spacing(1.5),
  },
  alert: {
    borderRadius: 0
  },

}));

type DialogTitleProps = {
  label: string;
  error?: {
    error?: string;
    errors?: string[];
  }
} & StandardProps;

const DialogTitle: React.FC<DialogTitleProps> = ({ className, label, error, ...others }) => {
  const classes = useStyles();


  return (
    <>
      <Typography variant='h3' align={'center'} className={classes.root}>{label}</Typography>
      {error && error.errors && Array.isArray(error.errors) && (
        error.errors.map((error: string) => (
          <Alert className={classes.alert} severity="error">{error}</Alert>)
        ))}
      {error && error.error && (
        <Alert className={classes.alert} severity="error">{error.error}</Alert>
      )}
    </>
  );
};

export default DialogTitle;
