import React, { useContext } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { Theme } from "../../../theme";
import TranslateContext from "../../../TranslateContext";
import SearchBlock from "../common/SearchBlock";
import ItemListView from '../common/ItemListView';


const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {},
}));

const SelectedBoards: React.FC<StandardProps> = ({ className, ...others }) => {
  const classes = useStyles();
  const {
    selectedCookbooks: {
      title
    }
  } = useContext(TranslateContext);

  return (
    <div className={classNames(classes.root, className)}>
      <ItemListView
        queryDataUrl={'api/featured/boards'}
        grid={{ xs: 12, md: 4 }}
        title={title}
      />
      <SearchBlock />
    </div>
  );
};

export default SelectedBoards;
