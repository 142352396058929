import React from "react";
import { Avatar, Button, Hidden, makeStyles, Typography } from "@material-ui/core"
import { Theme } from "../../../theme";
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { User } from '../../../globalTypes/User';
import { Metric } from '../../../globalTypes/AppData';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  profileRoot: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
  },
  avatarWithButton: {
    display: "flex",
    flexDirection: "column",
    textTransform: "none",
    alignItems: "center"
  },
  avatar: {
    width: '100px',
    height: '100px',
    marginBottom: spacing(3),
    [breakpoints.down("sm")]: {
      width: 120,
      height: 120,
    }
  },
  userData: {
    paddingLeft: spacing(5),
    [breakpoints.down("sm")]: {
      flex: '1 1 auto',
      textAlign: 'right',
    }
  },
  name: {
    textShadow: 'none',
    fontFamily: 'Amiri',
    fontStyle: 'italic',
    fontSize: '25px'
  },
  socPanel: {},
  metric: {
    display: "inline-flex",
    paddingRight: spacing(2),
    alignItems: 'center',
    '&:last-of-type': {
      paddingRight: 0
    }
  },
  metricIcon: {
    width: "1.4rem",
    height: "1.4rem",
    paddingLeft: spacing(.5),
  },
  dense: {
    margin: spacing(0, 4),
    flex: '1 1 auto',

    '& $avatar': {
      width: 100,
      height: 100,
    },

    '& $userData': {
      flex: '1 1 auto',
      textAlign: 'right',
      paddingLeft: spacing(2)
    },

    '& $name': {
      fontSize: '1.5rem'
    }
  }
}));

type ProfileProps = {
  data: any;
  loggedUser?: User | null | undefined
  edit?: {
    path: string;
    label: string;
  },
  metrics: Metric[],
  avatarPath: string,
  asItem?: boolean
} & StandardProps;

const Profile: React.FC<ProfileProps> = ({
                                           className,
                                           data,
                                           loggedUser,
                                           edit,
                                           metrics,
                                           avatarPath,
                                           asItem = false,
                                           ...others
                                         }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.profileRoot, className, { [classes.dense]: asItem })} {...others}>
      <div className={classes.avatarWithButton}>
        <Avatar className={classes.avatar} src={avatarPath} />


      </div>
      <div className={classes.userData}>
        <Typography variant="h6" className={classes.name}>{data.name}</Typography>

        <div className={classes.socPanel} style={{}}>
          {metrics.map(metric => (
            <MetricComponent metric={metric} key={metric.name} />
          ))}
        </div>


        {loggedUser && edit && (
          <Hidden smDown>
            <Button style={{fontFamily: "fahkwang", fontSize: "14px", padding: "0px"}}
                    component={RouterLink}
                    to={{ pathname: edit.path }}>{edit.label}
            </Button>
          </Hidden>)}

        <Hidden smDown>
          <Typography>{data.about}</Typography>
        </Hidden>
      </div>
    </div>
  );
};

type MetricProps = {
  metric: Metric
} & StandardProps;

const MetricComponent: React.FC<MetricProps> = ({
                                                  metric
                                                }) => {
  const classes = useStyles();

  return (
    <div className={classes.metric}>
      <Typography variant="subtitle1">{metric.count}</Typography>
      <img alt="This is cookbook illustration" src={metric.iconPath}
           className={classes.metricIcon} />
    </div>
  )
};
export default Profile;
