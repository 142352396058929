import { useCallback, useEffect, useState } from "react";
import axios, { AxiosRequestConfig, Method } from "axios";
import { path } from "ramda";

interface PRO {
  url?: string;
  initialData?: any;
  config?: AxiosRequestConfig;
}

export type UseQueryApi = (
  data: PRO
) => {
  isLoading: boolean;
  isError: boolean;
  data: any;
  getData: (url: string) => any;
};

interface PR {
  url: string;
  config?: AxiosRequestConfig;
  method?: Method;
}

export type UseMutationApi = (
  data: PR
) => {
  isLoading: boolean;
  isError: boolean;
  errorMessage?: any;
  response: any;
  sendData: (data: any) => void;
};

export const API_URI = process.env.REACT_APP_API_URI;

export const useQueryApi: UseQueryApi = ({
                                           url = "",
                                           initialData,
                                           // config = {},
                                         }) => {
  const [ data, setData ] = useState(initialData);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isError, setIsError ] = useState(false);

  const fetchData = useCallback(async (url: string) => {
    setIsError(false);
    setIsLoading(true);
    setData(initialData);
    try {
      const result = await axios(`${API_URI}/${url}`, {
        withCredentials: true,
        // ...config,
      });
      setData(result.data);
    } catch (error) {
      setIsError(true);
    }
    setIsLoading(false);
  }, [initialData]);

  useEffect(() => {
    if (url !== "") {
      fetchData(url);
    }
  }, [ url, fetchData ]);

  const getData = useCallback(fetchData, [ url ]);

  return { data, isLoading, isError, getData };
};

export const useMutationApi: UseMutationApi = ({
                                                 url = "",
                                                 config = {},
                                                 method = "post",
                                               }) => {
  const [ response, setResponse ] = useState();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isError, setIsError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState<string>();
  const sendData = useCallback(
    async (data) => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios({
          withCredentials: true,
          baseURL: API_URI,
          url,
          method,
          data,
          ...config,
        });
        setResponse(result.data);
      } catch (error) {
        setIsError(true);
        setErrorMessage(path([ "response", "data" ], error));
      }
      setIsLoading(false);
    },
    [ url, config, method ]
  );

  return { response, isLoading, isError, errorMessage, sendData };
};
