import React, { FormEvent, useContext, useEffect, useState } from "react";
import { Grid, Button, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import { globalFormTopMargin, Theme } from "../../../theme";
import { useMutationApi } from "../../../hooks/api";
import { getErrorMessage } from "../../common/errorMessage";
import { Alert } from "@material-ui/lab";
import TranslateContext from "../../../TranslateContext";
import SuccessView from '../../components/successView/SuccessView';
import CenteredCircularProgress from '../../common/CenteredCircularProgress';

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    marginTop: globalFormTopMargin,
    [breakpoints.down("sm")]: {
      padding: spacing(1, 0, 1, 0),
      marginTop: spacing(0),
      display: "flex"
    }
  },

  headerText: {
    padding: spacing(2.5, 0),
    fontWeight: 600,
    textAlign: "center",
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    borderTopRightRadius: spacing(1.5),
    borderTopLeftRadius: spacing(1.5),

    [breakpoints.down("sm")]: {
      backgroundColor: palette.background.default,
      color: palette.primary.main
    },
  },

  content: {
    padding: spacing(1),

  },

  margin: {
    margin: spacing(2),
    [breakpoints.down("sm")]: {
      margin: spacing(0),
    },
  },

  buttonRow: {
    marginTop: spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    [breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      "& a:first-of-type": {
        marginTop: spacing(2)
      },
    },
  },

  actionButtons: {
    textTransform: "none",
    padding: spacing(1),
    margin: spacing(.5)
  },

  paper: {
    padding: spacing(1),
    margin: "0 auto",
    maxWidth: 500,
    boxShadow: 'none',
    [breakpoints.down("sm")]: {
      maxWidth: "unset",
      width: "100%"
    },
  },

  textfield: {
    background: "#000000",
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    "&:hover, &:focus": {
      outline: "10",
      outlineOffset: "10px",
    },
  },

  multilineColor: {
    color:'white',
    textAlign: "center",
    shrink: true,
    fontSize: "18px",
  },

  button: {
    textAlign: "center",
    width: '100%'
  },

  title: {
    fontWeight: 300,
    lineHeight: 1.5,
    margin: spacing(0, 0, 3)
  },

  subTitle: {
    fontFamily: "Amiri",
    fontSize: "17px"
  }


}));

enum State {
  Loading,
  Form,
  Success
}

const PasswordReset: React.FC<StandardProps> = ({ className, ...others }) => {
  const classes = useStyles();
  const {
    passwordReset: {
      title, subTitle, buttons, emailInputLabel, successLabel, successContent
    }
  } = useContext(TranslateContext);
  const [ email, setEmail ] = useState("");
  const [ isSent, setSent ] = useState(false);
  const [ state, setState ] = useState(State.Form);

  //todo: solve illogical API post (because user ID does not know),
  const { isError, isLoading, errorMessage, sendData } = useMutationApi({
    url: `/id/password`,
    method: "post"
  });

  const handleForm = (event: FormEvent) => {
    event.preventDefault();
    sendData({
      user: {
        email
      }
    });
    setSent(true);
  };

  useEffect(() => {
    if (isLoading) {
      setState(State.Loading);
    } else {
      if (!isSent || (isSent && isError)) {
        setState(State.Form);
      }
      if (isSent && !isError) {
        setState(State.Success);
      }
    }
  }, [ isLoading, isError, isSent ])

  return (
    <>
      {state === State.Loading && <CenteredCircularProgress />}
      {state === State.Success && <SuccessView label={successLabel} content={successContent} routeLinkPath={'/login'}/>}
      {state === State.Form && (
        <form {...others} onSubmit={handleForm} className={classes.root}>
          <Paper className={classes.paper}>
            {errorMessage && errorMessage.error && (
              <Alert severity="error">{errorMessage.error}</Alert>
            )}
            <div className={classes.content}>
              <div className={classes.margin}>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                <Typography variant='h3' className={classes.title} align={'center'}>
                  {title}&nbsp;
                </Typography>
                  <Typography variant='h3' align={'center'} className={classes.subTitle} color={'primary'}>{subTitle}</Typography>
                </Grid>
              </Grid>
                
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12} sm={12}>
                  
                
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={emailInputLabel}
                  fullWidth
                  type="email"
                  required
                  autoFocus
                  className={classes.textfield}
                  InputProps={{
                  disableUnderline: true,
                  classes: {
                      input: classes.multilineColor
                  }
              }}
                  {...getErrorMessage(errorMessage, "email")}
                />

                </Grid>
              </Grid>

              </div>

              <Grid item xs={12} sm={12} className={classes.button} style={{ marginTop: "40px" }}>
              
              <Button
                variant="outlined"
                type="submit"
                style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", height: "70px", width: "304px", color: "#FF8A31", fontSize: "20px", letterSpacing: "2px" }}
                fullWidth
              >
                  {buttons.passwordReset}
                </Button>

            </Grid>
            </div>
          </Paper>
        </form>
      )}
    </>
  );
};

export default PasswordReset;
