import React, { useContext, useEffect } from "react";
import classNames from "classnames";
import { Button, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { Theme } from "../../../theme";
import SearchBlock from "../../components/common/SearchBlock";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { userContext } from "../../../state/userContext";
import Item from "../../components/common/Item";
import { AppData_selectedCookbook, AppData_userDetail, ItemVariant, Metric } from "../../../globalTypes/AppData";
import TranslateContext from "../../../TranslateContext";
import { useQueryApi } from "../../../hooks/api";
import Profile from '../profile/profile';


const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {},

  profileButton: {
    [breakpoints.down("sm")]: {
      width: '100%'
    },
    textTransform: "uppercase",
    borderColor: "black",
    borderRadius: "40px",
    color: "rgb(255, 138, 49)",
    fontSize: "14px",
    letterSpacing: "2px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    padding: "5px 15px",
    backgroundColor: "white",
    boxShadow: "none",
  },
  grid: {
    padding: spacing(7, 0, 2),
    '& $item': {
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
    },
    [breakpoints.down("sm")]: {
      paddingTop: spacing(4)
    }
  },
  item: {/*do not remove - nested class */ },
}));

const UserProfile: React.FC<StandardProps> = ({ className, ...others }) => {
  const classes = useStyles();
  const {
    userProfile: {
      addBoard, editProfile
    }
  } = useContext(TranslateContext);
  const history = useHistory();
  const { loggedUser } = useContext(userContext);
  const { username = loggedUser?.username } = useParams();
  const { isError: userError, data = {} } = useQueryApi({ url: `api/users/${username}` });
  const { isError: boardError, data: boards = [] } = useQueryApi({ url: `api/users/${username}/boards` });

  useEffect(() => {
    if (userError || boardError) {
      history.push(`/`);
    }
  }, [userError, boardError, history]);

  const isOwner = loggedUser && loggedUser.id === data.id;

  return (
    <div {...others} className={classNames(classes.root, className)}>
      <Profile
        data={data}
        avatarPath={data.avatar_url}
        loggedUser={loggedUser}
        edit={{ path: '/user-edit', label: editProfile }}
        metrics={getMetrics(data)} />
      <Hidden mdUp>
        <Typography>{data.about}</Typography>
        {loggedUser && (
          <Button variant="contained" color="primary"
                  component={RouterLink}
                  className={classes.profileButton}
                  to={{ pathname: "/user-edit" }}>{editProfile}
          </Button>)}
      </Hidden>
      {/*todo - replace with ItemList */}
      <Grid container spacing={6} className={classes.grid}>
        {isOwner && (
          <Grid item xs={12} md={3} className={classes.item}>
            <Item
              variant={ItemVariant.Action}
              action={{ path: "/add-board", label: addBoard }} />
          </Grid>)}
        {boards.map((data: AppData_selectedCookbook) => (
          <Grid item xs={12} md={3} key={data.id} className={classes.item}>
            <Item
              variant={ItemVariant.Board}
              data={data} />
          </Grid>))}
      </Grid>
      <SearchBlock />
    </div>
  );
};

export function getMetrics(user: AppData_userDetail) {
  if (!user) {
    return [];
  }
  const metrics: Metric[] = [
    { iconPath: '/images/book.svg', count: user.boards_count, name: 'boards_count' },
    { iconPath: '/images/user.svg', count: user.followers_count, name: 'followers_count' },
  ];

  return metrics;
}

export default UserProfile;
