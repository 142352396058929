import React from "react";
import ChangePassword from "./ChangePassword";
import { useMutationApi } from "../../../hooks/api";

const ChangePasswordView: React.FC<StandardProps> = ({ className, ...others }) => {
  const { errorMessage, sendData, response, isLoading } = useMutationApi({
    url: "/id",
    method: "patch",
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  });

  return <ChangePassword
    className={className}
    errorMessage={errorMessage}
    sendData={sendData}
    response={response}
    isLoading={isLoading}
    {...others} />;
};

export default ChangePasswordView;
