module.exports = {

  aboutPage: {
    text:
      `# Jak vznikl rozmanitý svět Vařme.cz
*„Při otevřeném okně v kuchyni, na začátku loňské jara, rodí se náš nápad.“*

Celou myšlenku podnítila obyčejná zapomnětlivost a nepořádek v receptech. Znáte to sami - chcete překvapit přátele skvělými miniburgery, hledáte recept na jahodový koláč na oslavu narozenin nejlepší kamarádky a nedokážete si vzpomenout, kde jste ten recept viděli. Byl to blog, webová stránka nebo snad časopis?

## Chtěli jsme sdílet naše oblíbené recepty
A tak vznikl Vařme.cz. Nemohli jsme si vzpomenout na jeden recept a najednou nás to trklo. Chyběl nám unikátní prostor, kde bychom měli všechny oblíbené recepty na jednom místě, vždy po ruce a pěkně zorganizované podle vlastních preferencí. Italská kuchyně, letní grilovačka, narozeniny, sladkosti pro děti… Zkrátka jsme začali tvořit místo, kde se můžeme inspirovat, pochlubit se vlastními výtvory a přeposílat recepty nebo rovnou celé kuchařky svým přátelům.

Největším zdrojem nápadů pro nás byl Pinterest. Skoro rok jsme usilovně pracovali a snažili se promítnout do našeho nápadu vše, co jsme si na začátku vysnili. Chtěli jsme, aby milovníci dobrého jídla náš projekt zbožňovali. Aby byl delikátní po všech stránkách. Museli jsme mu tedy vymyslet chutné a libozvučné jméno, které se ve světě neztratí. Nazvali jsme jej proto Vařme.cz.

## Vařme.cz - jediné místo pro vaše recepty
Vařme.cz je unikátní prostor, pro vaše oblíbené recepty. Libovolně je ukládáte do svých kuchařek podle zvolených preferencí. Jednu kuchařku můžete mít plnou sladkých jídel, jiné recepty může spojit jediná ingredience, další se může tematicky vztahovat třeba k oslavám narozenin. Možností je na Vařme.cz nepočítaně.

Báječnou hráškovou polévku, která chutná nejen celé rodině ale i kolegům v práci i mimčin medový dort, kterému nikdo neodolá, spojuje jedna věc. Poptávka po receptu. Už nemusíte hledat tužku a papír, ale jednoduše své přátele odkážete na Vařme.cz. Ať už chystáte slavnostní večeři pro rodinu, narozeninový dort pro vaši dlouholetou kamarádku, nebo tematickou párty, stačí jen všechny recepty vložit do on-line kuchařky a všechny žadatele o recept odkázat tam.

Jistě jste se již ocitli v některé z následujících situací „Dáš mi recept?“.

**Situace č.1 - Narozeninová oslava mé dobré kamarádky. Všem chutná čokoládový dort s jahodami. Už po prvním soustu slyším: „Dáš mi recept?**“

**Situace č.2 - Mám rande a rozhodla jsem se uvařit doma. Vařím špagety Carbonara, ještě jsem se skoro nestačila usadit a už slyším: „Dáš mi recept?**“

**Situace č.3 - Peču koláč pro kolegyni z práce, která neustále hledá nápady, co upeče pro své ratolesti. Špaldová buchta s jablky má velký úspěch. Kolegyně mi šeptá do ucha: „Dáš mi recept?**“

**Situace č.4 - Nedělní oběd u maminky. Svíčková a domácí knedlík se povedla víc než jindy. „Dáš mi recept?**“

Pokud jste se našli alespoň v jedné situaci, gratulujeme a vítáme vás v našem světě nejlepších jídel a všemožných laskomin. Na Vařme.cz najdete ty nejlepší recepty, kuchařky vašich známých i recepty kuchařských velikánů. Přejeme Vám delikátní vaření.

## Náš tým`,
  },
  termsPage: {
    text: `# Všeobecné obchodní podmínky 
## 1. Všeobecná ustanovení
1.1 Provozovatelem internetové stránky www.varme.cz (dále jen „Internetová stránka“) je PROVOZOVATEL (dále jen „Provozovatel“).

1.2 Tyto všeobecné obchodní podmínky (dále jen „Všeobecné obchodní podmínky“) upravují smluvní vztahy a vymezují práva a povinnosti mezi Provozovatelem a Uživatelem.

## 2. Definice a interpretace
„Autorský zákon“ znamená zákon č. 121/2000 Sb., o právu autorském, o právech souvisejících s právem autorským a o změně některých zákonů, v platném znění.

„Občanský zákoník“ znamená zákon č. 40/1964 Sb., občanský zákoník, v platném znění.

„Obchodní zákoník“ znamená zákon č. 513/1991 Sb., obchodní zákoník, v platném znění.

„Osobní údaje“ znamenají jakákoliv údaje ve smyslu zákona č. 101/2000 Sb., zákon o ochraně osobních údajů, v platném znění, na základě kterých je možné Uživatele, kterým je fyzická osoba, přímo či nepřímo identifikovat.

„Smlouva“ znamená smlouvu uzavřenou mezi Provozovatelem a Uživatelem na jejímž základě dochází k poskytování Služeb. Nedílnou součást Smlouvy tvoří Všeobecné obchodní podmínky. Provozoval a Uživatel se dohodli, že závazkové vztahy vzniklé ze Smlouvy se řídí Občanským zákoníkem. V případě, že je Uživatelem fyzická osoba podnikatel nebo právnická osoba, smluvní vztah mezi Provozovatelem a Uživatelem se řídí Obchodním zákoníkem.

„Služby“ znamená služby vytváření, sdílení, komentování a uchovávání kuchařských receptů na Internetové stránce, které poskytuje Provozovatel Uživateli na základě Smlouvy.

„Uzavření Smlouvy“ znamená přijetí návrhu Provozovatele na uzavření Smlouvy ze strany Uživatele. K přijetí návrhu na uzavření Smlouvy dochází registrací Uživatele na Internetové stránce. K registraci může dojít dvěma způsoby: (i) Uživatel se zaregistruje prostřednictvím své emailové adresy nebo (ii) Uživatel se zaregistruje prostřednictvím svého profilu na Facebooku. Pokud se Uživatel zaregistruje na Internetové stránce prostřednictvím své emailové adresy, Provozovatel mu zašle přístupové údaje na jeho emailovou adresu uvedenou při registraci. Odesláním registrace Uživatel výslovně stvrzuje, že se seznámil se Všeobecnými obchodními podmínkami a že s nimi souhlasí. Pokud se Uživatel zaregistruje na Internetové stránce prostřednictvím svého profilu na Facebooku, jako přístupové údaje na Internetovou stránku budou sloužit jeho přístupové údaje k službě Facebook. Uživatel registrací prostřednictvím svého profilu na Facebooku rovněž výslovně stvrzuje, že se seznámil se Všeobecnými obchodními podmínkami a že s nimi souhlasí.

„Uživatel“ znamená osobu, která projeví vůli využívat Služeb Provozovatele.

„Účet Uživatele“ znamená účet Uživatele na Internetové stránce, prostřednictvím kterého jsou Uživateli poskytovány Služby Provozovatelem.

## 3. Předmět Smlouvy
Předmětem Smlouvy mezi Provozovatelem a Uživatelem je závazek Provozovatele poskytnout Uživateli Služby a závazek Uživatele Služby řádně užívat.

## 4. Práva a povinnosti Provozovatele
4.1 Provozovatel se zavazuje uchovávat údaje, které Uživatel uloží do aplikací na Internetové stránce, nejméně po dobu, po kterou bude provozovat Internetovou stránku a umožnit dodatečnou úpravu a zpracování těchto údajů. Pokud by se Provozovatel rozhodl dále neprovozovat Služby a Internetovou stránku, informuje Uživatele na jeho emailovou adresu v dostatečném předstihu, a to nejméně 14 dnů předem.

4.2 Provozovatel nenese odpovědnost za údaje, které Uživatel uloží do aplikací na Internetové stránce.

4.3 Provozovatel nenese odpovědnost za zneužití přístupového jména a hesla k Účtu Uživatele.

4.4 Provozovatel dále nenese odpovědnost za nemožnost plnění v případě vyšší moci. V případě takové události oznámí Provozovatel nemožnost plnění neprodleně Uživateli.

4.5 Provozovatel nenese odpovědnost za náplň a funkčnost obsahu, který je umístěn na internetových stránkách třetích subjektů.

## 5. Práva a povinnosti Uživatele
5.1 Uživatel se zavazuje užívat Služby v souladu s právním řádem České republiky.

5.2 Uživatel prohlašuje, že v případě, že na Internetovou stránku umístí jakýkoliv obsah, který může být považován za dílo ve smyslu Autorského zákona, je držitelem příslušných práv a oprávnění a může je Provozovateli kdykoliv prokázat. Uživatel uděluje dodáním díla ve smyslu Autorského zákona na Internetovou stránku Provozovateli právo užívat tento obsah v souladu s Autorským zákonem, zejména jej zveřejnit, publikovat a rozšiřovat, a to v elektronické či jakékoliv jiné podobě, bez teritoriálního, časového a množstevního omezení, a bez úplaty. Provozovatel může obsah dále rozšiřovat také jako součást jiného obsahu. Provozovatel je oprávněn poskytnout licenci k užívání obsahu ve stejném rozsahu také třetím osobám.

5.3 Uživatel nese odpovědnost za udržení svého přístupového jména a hesla k Účtu Uživatele v tajnosti.

5.4 Uživatel je povinen poskytnout při uzavření Smlouvy pravdivé údaje o své osobě.

5.5 Uživatel se tímto vzdává vůči Provozovateli náhrady škody, která mu v budoucnu vznikne nebo může vzniknout v důsledku porušení povinností Provozovatele vyplývajících ze Smlouvy nebo jiných právních předpisů, s výjimkou nároků na náhradu škody, kterých se podle platných právních předpisů nelze platně vzdát.

## 6. Ukončení Smlouvy
6.1 Smlouvu lze ukončit (i) dohodou Provozovatele a Uživatele, (ii) odstoupením od Smlouvy učiněném Provozovatelem nebo Uživatelem v souladu se Všeobecnými obchodními podmínkami nebo (iii) výpovědí Smlouvy.

6.2 Provozovatel je oprávněn okamžitě odstoupit od Smlouvy, když dojde k hrubému porušení povinností Uživatele plynoucích ze Smlouvy nebo porušení právního řádu České republiky.

6.3 Uživatel je oprávněn okamžitě odstoupit od Smlouvy v případě hrubého porušení povinností Provozovatele plynoucích ze Smlouvy.

6.4 Každá smluvní strana je oprávněna Smlouvu vypovědět s měsíční výpovědní lhůtou.

6.5 Uživatel, který je fyzickou osobou spotřebitelem, je podle § 53 odst. 7 Občanského zákoníku, oprávněn odstoupit od Smlouvy, v případě, že byla uzavřena při použití prostředků komunikace na dálku, bez uvedení důvodu a bez jakékoliv sankce, a to ve lhůtě 14 kalendářních dnů od uzavření Smlouvy.

6.6 Odstoupení od Smlouvy z jakéhokoli výše uvedeného důvodu a výpověď Smlouvy kteroukoli ze stran Smlouvy musí být učiněno písemnou formou.

## 7. Ochrana Osobních údajů a marketingová komunikace
7.1 Ochrana osobních údajů uživatele webové stránky, včetně podmínek pro zasílání obchodních sdělení, se řídí pravidly nakládání s osobními údaji, jejichž aktuální plné znění je k dispozici v dokumentu Zásady ochrany osobních údajů a soukromí.

## 8. Internetová stránka Provozovatele
8.1 Přístup a používání Internetové stránky je bezplatné.

8.2 Uživatel bere na vědomí, že z technických či jiných důvodů může být ve výjimečných případech přístup k Internetové stránce omezen nebo přerušen.

8.3 Provozovatel je držitelem příslušných autorských práv v souladu s Autorským zákonem. Obsah Internetové stránky nelze uchovávat, upravovat ani šířit bez souhlasu Provozovatele. To neplatí v případě šíření obsahu Internetové stránky k nekomerčním účelům za předpokladu, že takové šíření není způsobilé ohrozit autorská práva Provozovatele. Takto udělený souhlas se šířením obsahu Internetové stránky může být Provozovatelem kdykoliv odvolán.

## 9. Závěrečná ustanovení
9.1 Veškeré smluvní vztahy mezi Provozovatelem a Uživatelem se řídí právními předpisy České republiky.

9.2 Vztahy, které výslovně neupravují tyto Všeobecné obchodní podmínky, se řídí Občanským zákoníkem, pokud je Uživatelem fyzická osoba spotřebitel, v ostatních případech se řídí Obchodním zákoníkem.

9.3 Všeobecné obchodní podmínky, které jsou uveřejněny na Internetové stránce, si Provozovatel vyhrazuje kdykoliv změnit. Pro Uživatele jsou závazné vždy platné a účinné Všeobecné obchodní podmínky ve znění uveřejněném na Internetové stránce v den uzavření Smlouvy.

9.4 Pro účely Všeobecných obchodních podmínek se za dodržení písemné formy považuje též komunikace prostřednictvím elektronické pošty.

9.5 Pokud je některé ustanovení Všeobecných obchodních podmínek neplatné nebo nevynutitelné, platnost či vynutitelnost ostatních ustanovení Všeobecných obchodních podmínek tím nebude dotčena.

9.6 Tyto Všeobecné obchodní podmínky jsou platné a účinné od 24.5.2018.`,
  },

  privacyPolicy: {
    text: `
# Zásady ochrany osobních údajů a soukromí
            
## Jaké osobní údaje zpracováváme?

Zpravováváme pouze osobní údaje, které nám poskytnete v souvislosti s používáním služby varme.cz.

Jedná se zejména o údaje poskytnuté při registraci:

* e-mailová adresa
* jméno a příjmení

V případě registrace pomocí služby Facebook:

* e-mailová adresa
* jméno a příjmení
* facebook uživatelské jméno
* facebook identifikátor

## Pro jaké účely Vaše osobní údaje zpracováváme?

Hlavním důvodem, zpracování osobní údajů uživatelů je správa Uživatelských účtů, aby mohla být poskytnuta samotná služba

Osobní údaje uživatelů dále zpravováváme za účelem:

* provádění analýz a měření s cílem zjistit, jak je službapoužívána
* základní analýzy preferencí Uživatelů za účelem zobrazování obsahu, který odpovídá individuálním potřebám Uživatelů
* zasílání obchodních sdělení včetně nabídky produktů a služeb třetích stran (odhlášení z přijímání obchodních sdělení je možno provést v obdržené zprávě, pro kterou se Uživatel k odběru takovýchto sdělení zaregistroval, popřípadě e-mailem na e-mailovou adresu podpora@varme.cz) a obsahových newsletterů,

## Kdo má přístup k Vašim osobním údajům?

Chráníme a zajišťujeme bezpečnost Vašich osobních údajů. Pečlivě vybíráme své partnery, kterým tato data svěřujeme a kteří jsou schopni zajistit takové technické a organizační zabezpečení Vašich osobních údajů, aby nemohlo dojít k neoprávněnému nebo nahodilému přístupu k osobním údajům či k jejich jinému zneužití. Žádný z partnerů nesmí využít poskytnuté osobní údaje k žádným jiným účelům, než ke kterým jim byly zpřístupněny.

Třetími stranami, které mohou mít přístup k Vašim osobním údajům, jsou:

* osoby, které pro Vařme.cz zajišťují technický provoz určitých služeb či provozovatelé technologií, které Vařme.cz využívá, zejména infrastrukturních a IT technologií a služeb,
* poskytovatelé služeb, kteří poskytují služby Vařme.cz, jež se týkají marketingových činností, včetně přímého marketingu, zpracovávání průzkumů trhu a/nebo provádění statistických analýz a remarketingu prostřednictvím sociálních médií, jako jsou např. Facebook a Google, osoby, které pro Vařme.cz zajišťují dostatečné zabezpečení a integritu jejich služeb a webů,

* poskytovatelé platebních brán,
* poskytovatelé účetních a právních služeb a dalších obdobných služeb na základě smluvního či jiného právního vztahu.

Za určitých podmínek jsme též povinni některé Vaše osobní údaje předat na základě platných právních předpisů např. orgánům činným v trestním řízení a dalším orgánům veřejné správy.

## Použití souborů Cookie

Vy jako uživatelé máte možnost používání cookies odmítnout. Pokud bude mít Váš prohlížeč použití cookies povoleno, bude Vařme.cz vycházet z toho, že souhlasíte s využíváním standardních cookies Stránkami Vařme.cz.

Ve většině internetových prohlížečů lze výše uvedené cookies spravovat v nastavení prohlížeče. Více informací o cookies a o tom, jak zjistit, jaké cookies byly nastaveny a jak je spravovat a odstraňovat, najdete na internetových stránkách www.aboutcookies.org a www.allaboutcookies.org.

Pokud chcete změnit nastavení souborů cookies nainstalované službou Google Analytics, navštivte stránku http://tools.google.com/dlpage/gaoptout.

Berte prosím na vědomí, že případná změna nastavení souborů cookies ve Vašem internetovém prohlížeči může ovlivnit správné fungování Stránek Vařme.cz.

## Jak dlouho Vaše osobní údaje zpracováváme?

Zpracováváme Vaše osobní údaje po celou dobu využívání Vařme.cz a následně na základě Vámi uděleného souhlasu po dobu dalších 5 let, nebude-li tento Váš souhlas se zpracováním osobních údajů z Vaší strany odvolán.

Zde si Vás však dovolujeme upozornit, že ty osobní údaje, které jsou nezbytné pro řádné poskytnutí služeb Vařme.cz, resp. pro splnění všech našich povinností, vyplývajících z obecně závazných právních předpisů, musíme zpracovávat bez ohledu na Vámi udělený souhlas po dobu stanovenou příslušnými právními předpisy či v souladu s nimi i po případném odvolání Vašeho souhlasu.

Údaje získané v rámci Registrace, pokud je možné tyto osobní údaje zpracovávat i bez Vašeho souhlasu, pak zpracováváme po dobu využívání služeb Vařme.cz, po dobu do zrušení Registrace z Vaší strany. Následně jsou obvykle ukládány po přiměřenou dobu pouze základní identifikační údaje a údaj o tom, z jakého důvodu byla Registrace ukončena či údaje tvořící součást provozních záloh.

## Můžeme Vaše osobní údaje zpracovávat i bez Vašeho souhlasu?
    
Ano, Vaše osobní údaje můžeme zpracovávat i bez Vašeho souhlasu, ovšem pouze za účelem:

* poskytování služeb Vařme.cz,
* plnění právních povinností, které pro nás vyplývají z obecně závazných právních předpisů (např. daňové nebo účetní povinnosti), či
* oprávněných zájmů Vařme.cz, za předpokladu, že tyto oprávněné zájmy nejsou v rozporu oprávněnými zájmy a základními právy a svobodami Uživatele (např. k přímému marketingu, zajištění bezpečnosti našich webů apod.).
Možnost a zákonnost takového zpracování osobních údajů vyplývá přímo z platných právních předpisů (zejména Nařízení), a Váš souhlas k tomuto zpracování tudíž není potřeba.


## Na základě čeho můžeme zpracovávat Vaše osobní údaje?

Vařme.cz může Vaše osobní údaje zpracovávat jednak na základě Vámi uděleného souhlasu, ale dále například též na základě jejího oprávněného zájmu (zejména zpracování osobních údajů za účelem přímého marketingu). V neposlední řadě je pak důvodem umožňujícím Vařme.cz zpracovávat Vaše osobní údaje i bez Vašeho souhlasu plnění povinností vyplývající pro naši společnost ze zákona. Jednotlivé účely zpracování jsou podrobněji popsány v sekci Pro jaké účely Vaše osobní údaje zpracováváme? těchto Zásad.


Vyžadují-li to příslušné právní předpisy, může Vás Vařme.cz požádat, abyste udělili výslovný souhlas se zpracováním svých osobních údajů pro účely přímého marketingu (zasílání newsletterů a jiných obchodních sdělení). Takový souhlas může být Uživatelem kdykoliv odvolán zasláním e-mailu na e-mailovou adresu podpora@varme.cz nebo pomocí odkazu k odhlášení z odběru marketingových informací, který je uveden v každém e-mailu od Vařme.cz obsahujícím marketingové sdělení.

## Jak jsou Vaše osobní údaje zabezpečeny?

Vařme.cz chrání Vámi poskytnuté osobní údaje v maximální možné míře pomocí moderních technologií, které odpovídají stupni technického rozvoje. Vařme.cz pravidelně kontroluje, zda systém neobsahuje slabá místa a nebyl vystaven útokům. Vařme.cz používá taková bezpečnostní opatření, která s ohledem na aktuální stav technologií poskytují dostatečné zabezpečení, aby pokud možno nedošlo k neoprávněnému přístupu k Vašim osobním údajům. Přijatá bezpečnostní opatření jsou pak pravidelně aktualizována. Přístup k Vašim osobním údajům mají pouze osoby oprávněné k zacházení s osobními údaji.

Za účelem lepšího zabezpečení Vašich osobních údajů je přístup k těmto údajům chráněn heslem. Doporučujeme tedy, abyste uchovávali svá jedinečná hesla a další přístupové údaje k službám Vařme.cz v tajnosti a dodržovali základní bezpečnostní zásady.

## Jak a kdy můžete svůj souhlas se zpracováním osobních údajů odvolat?

Váš dobrovolně udělený souhlas se zpracováním osobních údajů můžete kdykoli bezplatně odvolat, a to prostřednictvím zaslání e-mailové zprávy na adresu: podpora@varme.cz. Odvolání souhlasu nemá vliv na zpracování osobních údajů, které zpracováváme na jiném právním základu, než je souhlas (tj. zejména je-li zpracování nezbytné pro poskztnutí služby, právních povinností či z jiných důvodů uvedených v platných právních předpisech).

## Jaká práva máte v souvislosti s ochranou Vašich osobních údajů?

Ve vztahu k Vašim osobním údajům máte zejména následující práva:

* právo svůj souhlas kdykoli odvolat,
* právo osobní údaje opravit či doplnit,
* právo požadovat omezení zpracování,
* právo vznést námitku či stížnost proti zpracování v určitých případech,
* právo požadovat přenesení osobních údajů (včetně práva bezplatně získat své zpracovávané osobní údaje ve strukturovaném, běžně používaném a strojově čitelném formátu),
* právo na přístup k osobním údajům,
* právo být informován o porušení zabezpečení osobních údajů v určitých případech,
* právo na výmaz osobních údajů v určitých případech (včetně práva být „zapomenut“), a případně též
* další práva stanovená v Zákoně o ochraně osobních údajů a Nařízení (po nabytí jeho účinnosti).`,

  },
};
