import React from "react";
import classNames from "classnames";
import { Hidden, Button, makeStyles, Paper, Typography } from "@material-ui/core";
import { globalFormTopMargin, Theme } from "../../../theme";
import DialogTitle from '../common/DialogTitle';
import { Link as RouterLink } from 'react-router-dom';


const useStyles = makeStyles(({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    marginTop: globalFormTopMargin,
    [breakpoints.down("sm")]: {
      padding: spacing(1, 0, 1, 0),
      marginTop: spacing(0),
      display: "flex",
      flexDirection:'column'
    }
  },

  content: {
    padding: spacing(1),
  },

  margin: {
    margin: spacing(2),
    [breakpoints.down("sm")]: {
      margin: spacing(0),
    },
  },

  actionRow: {
    marginTop: spacing(2),
    display: "flex",
    justifyContent: "center",
  },

  actionButton: {
    textTransform: "none",
    marginTop: spacing(1),
    [breakpoints.down("sm")]: {
      width: '100%'
    }
  },

  paper: {
    margin: "0 auto",
    maxWidth: 500,
    [breakpoints.down("sm")]: {
      boxShadow: 'none',
      maxWidth: "unset",
      width: "100%"
    },
  },
}));

export type SuccessViewProps = {
  label: string;
  content?: string;
  actionLabel?: string;
  routeLinkPath: string
} & StandardProps;

const SuccessView: React.FC<SuccessViewProps> = ({ className, label, content, actionLabel="Pokračovat", routeLinkPath, ...others }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <Paper className={classes.paper}>
        <DialogTitle label={label} />
        <div className={classes.content}>
          {content && (<Typography className={classes.margin} style={{ textAlign: "center", marginBottom: "30px"}}>{content}</Typography>)}
          <div className={classes.actionRow}>
          <Hidden smDown>
              <Button
                  variant="outlined"
                  component={RouterLink}
                  to={routeLinkPath}
                  style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", height: "70px", color: "#FF8A31", fontSize: "20px", letterSpacing: "2px", width: "304px" }}
                  fullWidth
                >
                  {actionLabel}
                </Button>
              </Hidden>
              <Hidden mdUp>
                <Button
                  variant="outlined"
                  component={RouterLink}
                  to={routeLinkPath}
                  style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", height: "70px", color: "#FF8A31", fontSize: "20px", letterSpacing: "2px" }}
                  fullWidth
                >
                  {actionLabel}
                </Button>
              </Hidden>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default SuccessView;
