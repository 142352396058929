import React, { useContext, useEffect } from "react";
import theme from "./theme";
import Router from "./Router";
import { ThemeProvider } from "@material-ui/core";
import { useLoggedUser } from "./hooks/user";
import { userContext } from "./state/userContext";
import TranslateContext from "./TranslateContext";
import { useMutationApi } from "./hooks/api";

function App() {
  const user = useLoggedUser();
  const appData = useContext(TranslateContext);
  const { response, sendData } = useMutationApi({
    url: "id",
    method: "get",
  });
  useEffect(() => {
    //login after reload
    if (!user.loggedUser) {
      sendData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user.loggedUser && response && response.id) {
    user.setLoggedUser(response);
  }

  return (
    <ThemeProvider theme={theme}>
      <userContext.Provider value={user}>
        <TranslateContext.Provider value={appData}>
          <Router />
        </TranslateContext.Provider>
      </userContext.Provider>
    </ThemeProvider>
  );
}

export default App;
