import React, { useContext } from "react";
import SearchBlock from '../components/common/SearchBlock';
import { ItemVariant } from '../../globalTypes/AppData';
import ItemListView from '../components/common/ItemListView';
import TranslateContext from '../../TranslateContext';
import { userContext } from '../../state/userContext';

type Props = {
  variant: ItemVariant;
} & StandardProps;

const PinBoardView: React.FC<Props> = ({
                                         variant,
                                       }) => {

  const { loggedUser } = useContext(userContext);
  const boardTitle = useContext(TranslateContext).boardListView.title;
  const pinTitle = useContext(TranslateContext).pinListView.title;

  return (
    <>
    <SearchBlock />
      {variant === ItemVariant.Board && (
        <ItemListView
          variant={variant}
          title={boardTitle}
          queryDataUrl={loggedUser ? `api/users/${loggedUser.id}/boards` : 'api/search/boards'} />
      )}

      {variant === ItemVariant.Pin && (
        <ItemListView
          variant={variant}
          title={pinTitle}
          queryDataUrl={loggedUser ? `api/users/${loggedUser.id}/pins?page=1` : `api/pins?page=1`} />
      )}

      
    </>
  );
};

export default PinBoardView;
