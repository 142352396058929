import { Button, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { Theme } from '../../../theme';
import { API_URI } from '../../../hooks/api';
import TranslateContext from '../../../TranslateContext';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  facebook: {
    backgroundColor: '#4A68AD',
    width: "100%",
    height: "60px",
    borderRadius: 0,
    color: '#FFF',
    border: 'none',
    fontFamily: 'Helvetica, Arial, sans-serif',
    letterSpacing: '.25px',
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'clip',
    whiteSpace: 'nowrap',
    margin: "29px 0",
    textTransform: "uppercase",
    
    [breakpoints.down('sm')]: {
      fontSize: "0.7rem",
    },
    [breakpoints.up('md')]: {
      fontSize: "1rem",
    },

    '&:before': {
      content: '""',
      display: 'block',
      width: 24,
      height: 24,
      marginLeft: 20,
      background: 'url("https://static.xx.fbcdn.net/rsrc.php/v3/yN/r/szGrb_tkxMW.png") no-repeat',
      paddingRight: 12,
    },

    '&:hover': {
      backgroundColor: '#1877f2',
    }
  },
}))

const FacebookLogin: React.FC<StandardProps> = () => {
  const classes = useStyles();

  const { facebookLogin } = useContext(TranslateContext);

  return (
    <Button href={`${API_URI}/id/auth/facebook`} className={classes.facebook}>{facebookLogin.text}</Button>
  )
}

export default FacebookLogin;
