import React, { useContext } from 'react';
import { Redirect, Route } from "react-router-dom";
import { userContext } from '../../state/userContext';
import CenteredCircularProgress from "./CenteredCircularProgress";

type Props = {
  path: string;
};

const PrivateRoute: React.FC<Props> = ({ children, ...rest }) => {
  const { loggedUser } = useContext(userContext);


  // zakomentuj tento if, aby tě to pořád neodhlašovalo
  if (!loggedUser) {
    return (
      <Redirect to="/login" />
    )
  }

  return (
    <Route
      {...rest}
      render={() =>
        loggedUser === undefined ? (
          <CenteredCircularProgress />
        ) : (
          children
        )
      }
    />
  );
};

export default PrivateRoute;
