import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { cond, replace, T, test } from "ramda";
import { Theme } from "../../../theme";

const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    color: palette.primary.main
  }
}));

export type Props = {
  href: string;
} & StandardProps;

const isEmail = test(/^[a-zA-Z\d]+@[^.]+\..+$/);
const isPhone = test(/^\+?[0-9 ]+$/);

const getLinkPropsByType = cond([
  [ isEmail, email => ({ href: `mailto:${email}` }) ],
  [ isPhone, phone => ({ href: `tel:${replace(/ /g, "", phone)}` }) ],
  [ T, href => ({ href, target: "_blank", rel: "noopener noreferrer" }) ]
]);

const SmartLink: React.FC<Props> = ({ href, children, ...others }) => {
  const classes = useStyles({});

  const linkProps = getLinkPropsByType(href);

  return (
    <a {...others} {...linkProps} className={classNames(classes.root, others.className)}>
      {children}
    </a>
  );
};

export default SmartLink;
