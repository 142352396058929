import React, { useContext } from "react";
import LoginPage from "./LoginPage";
import { Redirect } from "react-router-dom";
import { userContext } from "../../state/userContext";

function LoginView() {
  const { loggedUser } = useContext(userContext);

  if (loggedUser) {
    return <Redirect to="/" />;
  }

  return <LoginPage />;
}

export default LoginView;
