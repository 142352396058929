import React, { FormEvent, useContext, useEffect, useState } from "react";
import { Button, makeStyles, Paper, TextField } from "@material-ui/core";
import { globalFormTopMargin, Theme } from "../../../theme";
import { getErrorMessage } from "../../common/errorMessage";
import { userContext } from "../../../state/userContext";
import TranslateContext from "../../../TranslateContext";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { assocPath, dissocPath, hasPath, mergeDeepLeft } from "ramda";
import DialogTitle from '../../components/common/DialogTitle';
import CenteredCircularProgress from '../../common/CenteredCircularProgress';

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    marginTop: globalFormTopMargin,
  },

  content: {
    padding: spacing(1),
  },

  margin: {
    margin: spacing(2),
    [breakpoints.down("sm")]: {
      margin: 0
    },
  },
  formActions: {
    marginTop: spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& a:first-of-type": {
      marginRight: spacing(1),
    },
  },
  actionButtons: {
    textTransform: "none",
    marginTop: spacing(1),
  },

  paper: {
    margin: "0 auto",
    maxWidth: 500,
  },
}));

export type DataProps = {
  user: {
    password: String;
    password_confirmation: String;
  };
};

type Props = {
  errorMessage: any;
  sendData: (data: DataProps) => void;
  response: any;
  isLoading?: boolean;
} & StandardProps;

const ChangePassword: React.FC<Props> = ({ className, errorMessage, sendData, isLoading,response, ...others }) => {
  const classes = useStyles();
  const {
    userEdit: { passwordChange: texts, buttons },
  } = useContext(TranslateContext);
  const history = useHistory();
  const { setLoggedUser } = useContext(userContext);
  const [ password, setPassword ] = useState("");
  const [ passwordConfirmation, setPasswordConfirmation ] = useState("");
  const [ errorMessages, setErrorMessages ] = useState({});

  useEffect(() => {
    if (response && !errorMessage) {
      setLoggedUser(response);
      history.push(`/${response.id}`);
    }
  }, [ response, errorMessage, history, setLoggedUser ]);

  const handleForm = (event: FormEvent) => {
    event.preventDefault();
    sendData({
      user: {
        password,
        password_confirmation: passwordConfirmation,
      },
    });
  };

  const passwordsComparison = (target: any, valueToCompare: string) => {
    if (valueToCompare !== target.value) {
      setErrorMessages(assocPath([ "errors", "password_confirmation" ], [ texts.passwordsNotSame ], errorMessages));
    } else if (hasPath([ "errors", "password_confirmation" ], errorMessages)) {
      setErrorMessages(dissocPath([ "errors", "password_confirmation" ], errorMessages));
    }
  };

  return (
    <>
      {isLoading && <CenteredCircularProgress />}
      {!isLoading && (
        <form {...others} onSubmit={handleForm} className={classes.root}>
          <Paper className={classes.paper}>
            <DialogTitle label={texts.title} error={errorMessage} />
            <div className={classes.content}>
              <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyUp={(e) => passwordsComparison(e.target, passwordConfirmation)}
                label={texts.newPassword}
                type="password"
                fullWidth
                required
                InputLabelProps={{
                  style: { color: '#000' },
                }}
                {...getErrorMessage(errorMessage, "password")}
              />

              <TextField
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                onKeyUp={(e) => passwordsComparison(e.target, password)}
                label={texts.repeatPassword}
                type="password"
                fullWidth
                required
                InputLabelProps={{
                  style: { color: '#000' },
                }}
                {...getErrorMessage(mergeDeepLeft(errorMessage, errorMessages), "password_confirmation")}
              />

              <div className={classes.formActions}>
                <Button 
                variant="outlined"
                style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", color: "#FF8A31", fontSize: "14px", letterSpacing: "2px" }}
                component={RouterLink} to={`/user-edit`} className={classes.actionButtons}>
                  {buttons.cancel}
                </Button>
                <Button 
                variant="outlined"
                style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", color: "#FF8A31", fontSize: "14px", letterSpacing: "2px" }}
                type="submit" className={classes.actionButtons}>
                  {buttons.changePassword}
                </Button>
              </div>
            </div>
          </Paper>
        </form>
      )}
    </>
  );
};

export default ChangePassword;
