import React from "react";
import classNames from "classnames";
import { Grid, makeStyles } from "@material-ui/core";
import { Theme } from "../../../theme";
import Item, { ItemProps } from "../common/Item";
import { GridSize } from '@material-ui/core/Grid/Grid';
import { ItemVariant } from '../../../globalTypes/AppData';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"


const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {},
}));

export type ItemsListProps = {
  items: any;
  grid?: {
    xs?: boolean | GridSize,
    sm?: boolean | GridSize,
    md?: boolean | GridSize,
    lg?: boolean | GridSize,
    xl?: boolean | GridSize,
  },
  firstItem?: React.ReactNode,
} & Partial<ItemProps> & StandardProps;

const ItemsList: React.FC<ItemsListProps> = ({
                                               className,
                                               items,
                                               grid = { xs: 6, sm: 4 ,md: 3 },
                                               firstItem,
                                               variant = ItemVariant.Pin,
                                               ...others
                                             }) => {
  const classes = useStyles();

  const gridLayout = (<>

    <ResponsiveMasonry
               columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1280: 4}}
           >
      <Masonry columnsCount={1} className={classNames(classes.root, className)}>
        {firstItem && (
          <Grid item xs={grid.xs} md={grid.md} key={'first-action'}>
            {firstItem}
          </Grid>
        )}
        {items && items.map((item: any) => (
          <div key={item.id} style={{padding: "15px"}}>
            <Item variant={variant} data={item} {...others} />
          </div>))}
      </Masonry>
    </ResponsiveMasonry>
  </> );

  return (
    gridLayout
  );
};

export default ItemsList;
