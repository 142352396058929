import React, { useCallback, useEffect } from "react";
import { UserContext } from '../state/userContext';
import { useQueryApi } from './api';

export const useLoggedUser = (): UserContext => {
  const [ loggedUser, setUser ] = React.useState();
  const [ boards, setBoards ] = React.useState([]);
  const setLoggedUser = React.useCallback((user) => {
    setUser(user);
  }, []);

  const { data, getData } = useQueryApi({});

  const getBoards = useCallback(() => {
    if (loggedUser) {
      // @ts-ignore -- it says, loggedUser could be undefined, but I do not know how it could be, when in if statement
      getData(`api/users/${loggedUser.id}/boards`);
    }
  }, [ loggedUser, getData ])

  useEffect(() => {
    getBoards()
  }, [ loggedUser, getBoards ])

  useEffect(() => {
    if (data) {
      setBoards(data)
    }
  }, [ data ])

  return {
    loggedUser,
    setLoggedUser,
    boards,
  };
};
