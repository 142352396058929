import React from "react";
import { AppData, SearchCategories } from "./globalTypes/AppData";

const imageLogoPath: string = "/images/logo.svg";
const transparentImageLogoPath: string = "/images/logo_invert.svg";

const TranslateContext = React.createContext<AppData>({
  global: {
    titleBrand: 'Vařme.cz'
  },
  header: {
    logo: {
      url: imageLogoPath,
      alt: "Varme.cz logo",
    },
    register: "Vytvořit profil",
    addRecipe: "Přidat recept",
    myProfile: "Můj profil",
    login: "Přihlásit se",
    logout: "Odhlásit se",
    menu: [
      {
        name: "Moje recepty",
        url: "/pins",
        private: true
      }, {
        name: "Moje kuchařky",
        url: "/boards",
        private: true
      }, {
        name: "Domů",
        url: "/",
        private: false
      }, {
        name: "Recepty",
        url: "/pins",
        private: false
      }, {
        name: "Kuchařky",
        url: "/boards",
        private: false
      }, {
        name: "O nás",
        url: "/about",
        private: false
      }, {
      name: "Párátko",
      url: "/paratko",
      private: false
    }
    ]
  },

  signUp: {
    pageTitle: { text: "Registrujte se" },
    subTitle: { text: "a sdílejte a ukládejte recepty. Vařte s námi." },
    emailLabel: "e-mail",
    usernameLabel: "jméno",
    passwordLabel: "heslo",
    buttons: {
      register: "Vytvořit profil"
    }
  },

  facebookLogin: {
    text: 'Přihlásit se přes Facebook'
  },

  login: {
    pageTitle: { text: "Přihlaste se" },
    subTitle: { text: "a zísejte přístup ke svým receptům a kuchařkám" },
    emailLabel: "e-mail",
    passwordLabel: "heslo",
    buttons: {
      login: "Přihlásit",
      forgottenPassword: "Zapomněl jsem heslo"
    }
  },

  homePage: {
    title: {
      text: "Aby se dobře žilo, musí se dobře jíst!",
    },

    subTitle: {
      text: "Objevujte a sbírejte recepty z celého světa.",
    },

    newestItemsHeaders: {
      newestRecipes: {
        text: "Najít nové recepty"
      },
      newestCookbooks: {
        text: "Nejnovější kuchařky"
      },
    }
  },
  userEdit: {
    title: "Nastavení",
    profileChange: {
      title: "Váš profil",
      avatar: "Změnit Avatara",
      yourName: "Vaše jméno",
      nickName: "Přezdívka",
      choseFile: "Vyberte soubor",
      noFileSelected: "Soubor nevybrán",
      changePassword: "Změna hesla",
    },

    passwordChange: {
      title: "Změna hesla",
      actualPassword: "Současné heslo",
      newPassword: "Nové heslo",
      repeatPassword: "Zopakujte heslo",
      passwordsNotSame: "Hesla se neschodují"
    },

    buttons: {
      cancel: "Zrušit",
      saveChanges: "Uložit změny",
      changePassword: "Změnit heslo"
    }
  },

  passwordReset: {
    title: "Zapomněli jste heslo?",
    subTitle: "Zadejte Vaši e-mailovou adresu.",
    emailInputLabel: "e-mail",
    buttons: {
      cancel: "Zrušit",
      passwordReset: "Obnovit heslo"
    },
    successLabel: "Zapomenuté heslo",
    successContent: "Pokyny pro změnu hesla byly odeslány na email",
  },

  boardDetail: {
    addPin: "+ Přidat recept",
  },

  addPin: {
    title: "Přidání receptu",
    pinNameLabel: "Zadejte název receptu",
    imgUrlLabel: "Zadejte cestu k obrázku",
    pinSourceLabel: "Zadejte odkaz receptu",
    buttons: {
      addPin: "Přidat recept",
      cancel: "Zrušit"
    }
  },

  addBoard: {
    title: "Přidání kuchařky",
    boardNameLabel: "Zadejte název kuchařky",
    boardDescription: "Zadejte popis kuchařky",
    buttons: {
      addBoard: "Přidat kuchařku",
      cancel: "Zrušit"
    }
  },

  boardListView: {
    title: {
      text: "Kuchařky",
    },
  },

  pinListView: {
    title: {
      text: "Najít nové recepty",
    },
  },

  selectedCookbooks: {
    title: {
      text: "Výběr z toho nejlepšího na ",
    },
  },

  boardBlock: {
    favoriteCookbooksButton: "Nejsledovanější kuchařky",
    aboutButton: "Více o vařme.cz",
    createCookbookButton: "Vytvoř kuchařku",
    selectedCookbooksButton: "Vybrané kuchařky"
  },

  mostVisitedCookbooks: {
    title: {
      text: "Nejsledovanější kuchařky na",
    },
  },

  searchBlock: {
    filterSelect: [
      {
        name: "Vše",
        value: SearchCategories.All
      },
      {
        name: "Recepty",
        value: SearchCategories.Pins
      },
      {
        name: "Kuchařky",
        value: SearchCategories.Boards
      },
      {
        name: "Uživatelé",
        value: SearchCategories.Users
      } ],

    searchBar: {
      label: "Co budete dnes vařit?"
    }
  },

  itemListView: {
    notFound: 'Pro hledaný výraz jsme nenašli žádné výsledky',
    showMore: 'Zobrazit více >>'
  },

  userProfile: {
    addBoard: "+ Přidat kuchařku",
    editProfile: "Upravit profil"
  },

  footer: {
    copyright: "© Vařme.cz 2020",
    email: "podpora@varme.cz",
    facebookLink: "https://www.facebook.com/varme.cz",
    instagramLink: "https://www.instagram.com/varme.cz",
    logo: {
      url: transparentImageLogoPath,
      alt: "Varme.cz logo",
    },
  },
  _site: {
    favicon: {
      url: "",
      alt: "",
      format: "",
      size: null,
      title: ""
    },
  }
});

export default TranslateContext;
