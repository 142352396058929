import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { Theme } from "../../../theme";

const useStyles = makeStyles(({ spacing, typography, breakpoints }: Theme) => ({
  root: {
    fontFamily: typography.fontFamily
  },
  content: {
    flex: "1 0 auto",
    padding: spacing(12, 3, 2),
    [breakpoints.down("sm")]: {
      padding: spacing(12, 4, 2),
      overflow: 'hidden'
    },
  },
}));

const Fork: React.FC<StandardProps> = ({ className, ...others }) => {
  const classes = useStyles();

  const createForkLink = () => {
    return {
      __html: `<a href="javascript:(function(){var script=document.createElement('script');script.dataset.baseUrl='https://www.varme.cz/';script.dataset.stylesheetUrl='https://www.varme.cz/bookmarklet/loader.css';script.type='text/javascript';script.charset='utf-8';script.src='https://www.varme.cz/bookmarklet/loader.js';document.head.appendChild(script);})();" onClick={(function(){return false;})} className="how-plus-works__button btn btn-default">
                      <div className="sipka-img">
                        <img src="images/fork/paratko.svg" alt="Párátko"/>
                      </div>
                    </a>`
    }
  }

  return (
    <div {...others} className={classNames(classes.root, className)}>


      <>
        <link href="/fork.css" rel="stylesheet" />

        <div id="content" style={{ marginTop: "70px" }}>
          <section id="banner-paratko">
            <div className="container">
              <div className="row justify-content-center align-items-center text-white">
                <div className="col-12 col-lg-8 text-center text-lg-start" style={{zIndex: 1, marginTop: "80px"}}>
                  <div className="paratko-flex">
                    <div dangerouslySetInnerHTML={createForkLink()} />
                    <div>
                      <p className="text-uppercase">Párátko</p>
                      <p className="d-none d-lg-block">Kurzorem přetáhněte tlačítko PÁRÁTKO<br />do svých oblíbených záložek v prohlížeči.</p>
                    </div>
                  </div>
                  <p className="d-block d-lg-none text-center paratko-text">Kurzorem přetáhněte tlačítko PÁRÁTKO<br />do svých oblíbených záložek v prohlížeči.</p>
                  <p className="fs-17 text-center mb-5 mb-lg-3">
                    Napíchněte si vlastní recept pomocí Páratka, které funguje jako záložka neboli “bookmark“,<br />
                    kterou uživatelé používají k ukládání nápadů, které nechtějí ztratit.<br />
                    Kliknutím na uložený recept na vařme.cz se dostanete na originální stránku s plným receptem.
                  </p>
                  <p className="text-yellow fs-17 text-center mt-5 mt-lg-3 pb-5 pb-lg-0">
                    *Pokud by to nešlo, přidejte url do svých záložek manuálně.<br />
                    *Pokud je nevidíte, zmáčkněte na klávesnici:<br /><span>CTRL + SHIFT + B (Windows)<br /></span><span>⌘ + SHIFT + B (Mac OS)</span>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>

    </div>
  );
};

export default Fork;
