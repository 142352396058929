import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQueryApi } from "../../../hooks/api";
import { userContext } from "../../../state/userContext";
import BoardDetail from "./BoardDetail";


const BoardDetailView: React.FC<StandardProps> = () => {
  const { loggedUser } = useContext(userContext);
  const { username, slug } = useParams();
  const history = useHistory();
  const queryUrl = `api/users/${username}/boards/${slug}`;
  const { isError, data: board = {} } = useQueryApi({ url: queryUrl });

  useEffect(() => {
    if (isError) {
      history.push(`/${username}`);
    }
  }, [isError, username, history]);



  const isOwner = loggedUser && board.owner && loggedUser.id === board.owner.id;

  return <BoardDetail
    data={board}
    isOwner={!!isOwner}
  />

};
export default BoardDetailView;
