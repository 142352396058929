import React, { FormEvent, useContext, useState } from "react";
import { Hidden, Button, Grid, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { globalFormTopMargin, Theme } from "../../theme";
import { useMutationApi } from "../../hooks/api";
import { getErrorMessage } from "../common/errorMessage";
import { userContext } from "../../state/userContext";
import TranslateContext from "../../TranslateContext";
import FacebookLogin from '../components/facebookLogin/FacebookLogin';
import PageTitle from '../components/pageTitle/pageTitle';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    marginTop: globalFormTopMargin,
    [breakpoints.down("sm")]: {
      padding: spacing(1, 0, 1, 0),
      marginTop: spacing(0),
      display: "flex"
    }
  },
  margin: {
    margin: spacing(2),
    [breakpoints.down("sm")]: {
      margin: spacing(0),
    },
  },

  fieldIcons: {
    [breakpoints.down("sm")]: {
      display: 'none'
    },
  },

  buttonRow: {
    marginTop: spacing(2),
  },

  button: {
    textAlign: "center",
    width: '100%'
  },

  paper: {
    
    margin: "0 auto",
    maxWidth: 500,
    boxShadow: 'none',
    [breakpoints.down("sm")]: {
      boxShadow: 'none',
      maxWidth: "unset",
      width: "100%"
    },
  },

  textfield: {
    background: "#000000",
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    "&:hover, &:focus": {
      outline: "10",
      outlineOffset: "10px",
    },
  },

  multilineColor: {
    color:'white',
    textAlign: "center",
    shrink: true,
    fontSize: "18px",
  },

  subTitle: {
    fontFamily: "Amiri",
    fontSize: "17px"
  }
}));

const SignUpView: React.FC<StandardProps> = (className, ...others) => {
  const classes = useStyles();

  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ name, setName ] = useState("");
  const { errorMessage, sendData, response } = useMutationApi({ url: "id" });
  const history = useHistory();
  const { setLoggedUser } = useContext(userContext);
  const {
    signUp: {
      passwordLabel, usernameLabel, emailLabel, buttons, pageTitle, subTitle
    }
  } = useContext(TranslateContext);

  const handleForm = (event: FormEvent) => {
    event.preventDefault();
    sendData({
      user: {
        email,
        password,
        name,
      },
    });
  };

  if (response && response.id) {
    history.push("/");
    setLoggedUser(response);
  }

  return (
    <form className={classNames(classes.root, className)} onSubmit={handleForm}>
      <Paper className={classes.paper}>
        <div className={classes.margin}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageTitle title={pageTitle} showBrand={false} />
              <Typography variant='h3' align={'center'} className={classes.subTitle} color={'primary'}>{subTitle.text}</Typography>
            </Grid>
          </Grid>
          
          <Grid container spacing={2} justify={'center'} alignContent={'center'} alignItems={'center'}>
            <Grid item xs={12}>
              <FacebookLogin />
            </Grid>
          </Grid>
          
          <Grid container spacing={1} alignItems="flex-end">

            <Grid item xs={12} sm={12}>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={emailLabel}
                className={classes.textfield}
                type="email"
                fullWidth
                autoFocus
                required
                InputProps={{
                  disableUnderline: true,
                  classes: {
                      input: classes.multilineColor
                  }
                }}
                {...getErrorMessage(errorMessage, "email")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end">

            <Grid item xs={12} sm={12}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={usernameLabel}
                className={classes.textfield}
                fullWidth
                required
                InputProps={{
                  disableUnderline: true,
                  classes: {
                      input: classes.multilineColor
                  }
                }}
                {...getErrorMessage(errorMessage, "name")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end">

            <Grid item xs={12} sm={12}>
              <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={passwordLabel}
                className={classes.textfield}
                type="password"
                fullWidth
                required
                InputProps={{
                  disableUnderline: true,
                  classes: {
                      input: classes.multilineColor
                  }
                }}
                {...getErrorMessage(errorMessage, "password")}
              />
            </Grid>
          </Grid>
          <Grid
            className={classes.buttonRow}
            container
            spacing={2}
            justify="space-between"
            alignItems={"flex-end"}
          >
            <Grid item xs={1} sm={6} />
            <Grid item xs={12} sm={12} className={classes.button} style={{ marginTop: "40px" }}>
              <Hidden smDown>
              <Button
                  variant="outlined"
                  type="submit"
                  style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", height: "70px", width: "304px", color: "#FF8A31", fontSize: "20px", letterSpacing: "2px" }}
                  fullWidth
                >
                  {buttons.register}
                </Button>
              </Hidden>
              <Hidden mdUp>
                <Button
                  variant="outlined"
                  type="submit"
                  style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", height: "70px", color: "#FF8A31", fontSize: "20px", letterSpacing: "2px" }}
                  fullWidth
                >
                  {buttons.register}
                </Button>
              </Hidden>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </form>
  );
};

export default SignUpView;
