import React from "react";
import SearchBlock from '../components/common/SearchBlock';
import { AppData_pageTitle, ItemVariant, SearchCategories } from '../../globalTypes/AppData';
import { useParams } from "react-router-dom";
import ItemListView from '../components/common/ItemListView';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  section: {
    marginTop: spacing(2),
  }
}));

type Props = {
  title?: AppData_pageTitle;
} & StandardProps;

const SearchView: React.FC<Props> = ({
                                       title,
                                     }) => {

  const classes = useStyles();
  const { category, searchQuery, page } = useParams();
  const all = category === SearchCategories.All;

  return (
    <>
      <SearchBlock />
      {all && searchQuery && (
        <>
          <ItemListView
            className={classes.section}
            limit={8}
            variant={ItemVariant.Pin}
            queryDataUrl={createQueryUrl(SearchCategories.Pins, searchQuery)}
            showAllUrl={createShowAllLink(SearchCategories.Pins, searchQuery)}
            title={{ text: 'Recepty na' }} />
          <ItemListView
            className={classes.section}
            limit={8}
            variant={ItemVariant.Board}
            queryDataUrl={createQueryUrl(SearchCategories.Boards, searchQuery)}
            showAllUrl={createShowAllLink(SearchCategories.Boards, searchQuery)}
            title={{ text: 'Kuchařky na' }} />
          <ItemListView
            className={classes.section}
            limit={8}
            variant={ItemVariant.User}
            queryDataUrl={createQueryUrl(SearchCategories.Users, searchQuery)}
            showAllUrl={createShowAllLink(SearchCategories.Users, searchQuery)}
            title={{ text: 'Uživatelé na' }} />
        </>
      )}

      {!all && searchQuery && (
        <ItemListView
          className={classes.section}
          variant={mapVariant(category)}
          queryDataUrl={createQueryUrl(category, searchQuery, page)}
          title={{ text: 'Recepty na' }} />
      )}
    </>
  );
};

export function createQueryUrl(category?: string, query?: string, page?: string) {
  if (page) {
    return `api/search/${category}?query=${query}&page=${page}`
  }
  return `api/search/${category}?query=${query}`
}

export function createShowAllLink(category?: string, query?: string, page?: string | number) {
  if (page) {
    return createPageLink(`/search/${category}/${query}`, page)
  }
  return `/search/${category}/${query}`
}

export function createPageLink(baseUrl: string, page: number | string): string {
  return `${baseUrl}/${page}`
}

//todo - TS
function mapVariant(category: SearchCategories | string = ''): ItemVariant {
  const variantMap: any = {
    [SearchCategories.Users]: ItemVariant.User,
    [SearchCategories.Boards]: ItemVariant.Board,
    [SearchCategories.Pins]: ItemVariant.Pin,
    [SearchCategories.All]: ItemVariant.Pin,
    '': ItemVariant.Pin,
  };

  return variantMap[category];
}

export default SearchView;
