import React, { useContext }  from "react";
import classNames from "classnames";
import { Button, makeStyles } from "@material-ui/core";
import { Theme } from "../../../theme";
import { userContext } from "../../../state/userContext";

const useStyles = makeStyles(({ spacing, typography, breakpoints }: Theme) => ({
  root: {
    fontFamily: typography.fontFamily
  },
  content: {
    flex: "1 0 auto",
    padding: spacing(12, 3, 2),
    [breakpoints.down("sm")]: {
      padding: spacing(12, 4, 2),
      overflow: 'hidden'
    },
  },
}));

const How: React.FC<StandardProps> = ({ className, ...others }) => {
  const classes = useStyles();
  const { loggedUser } = useContext(userContext);

  return (
    <div {...others} className={classNames(classes.root, className)}>
      

      <>
        <link href="/how.css" rel="stylesheet" />

        <div id="content">
          <section id="banner-o-varme" style={{backgroundImage: "url(images/how/jak-varme-funguje_cover.png)"}}>
            <div className="container">
              <div className="row justify-content-center justify-content-lg-around d-flex align-items-start" style={{paddingTop: "20%"}}>

                <div className="col-lg-4 col-md-6 col-sm-12 text-center">
                  <h2 className="uppercase-header mb-4" style={{fontSize: "20px"}}>Napichujte recepty</h2>
                  <p className="text-yellow"  style={{fontSize: "20px"}}>
                  Začněte vytvořením profil a nainstalováním funkce Párátko/Vidlička. A hned můžete začít napichovat recepty, na které narazíte na internetu. Líbil se vám recept na vašem oblíbeném blogu? Recept napíchněte na Párátko/Vidličku a přehledně uložte do svého profilu.
                  </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 text-center">
                  <h2 className="uppercase-header mb-4" style={{fontSize: "20px"}}>Sbírejte recepty</h2>
                  <p className="text-yellow" style={{fontSize: "20px"}}>
                  Čím víc receptů napíchnete, tím víc se bude Vařme zvětšovat. Najdete tu i všechny napíchnuté recepty ostatních gastro nadšenců z Vařme. A tyto recepty můžete ukládat do svého profilu.
                  </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 text-center">
                  <h2 className="uppercase-header mb-4" style={{fontSize: "20px"}}>Vytvářejte kuchařky</h2>
                  <p className="text-yellow" style={{fontSize: "20px"}}>
                  V kuchyni musí být pořádek. A proto své napíchnuté recepty můžete třídit do tematických kuchařek ve svém profilu.
                  </p>
                </div>


                
                {!loggedUser ? (
                  <a href="/sign-up" style={{ textDecoration: "none", textAlign: "center"}}>
                    <Button
                    variant="outlined"
                    type="submit"
                    style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", height: "70px", width: "304px", color: "#FF8A31", fontSize: "20px", letterSpacing: "2px", backgroundColor: "white" }}
                    fullWidth
                  >
                    Vytvořit profil
                  </Button>
                </a>
                ) : (

                  <a href="/sign-up" style={{ textDecoration: "none", textAlign: "center", display: "none"}}>
                    <Button
                    variant="outlined"
                    type="submit"
                    style={{ textTransform: "uppercase", borderColor: "black", borderRadius: "40px", height: "70px", width: "304px", color: "#FF8A31", fontSize: "20px", letterSpacing: "2px", backgroundColor: "white" }}
                    fullWidth
                  >
                    Vytvořit profil
                  </Button>
                </a>

                )}
              


              </div>

              
            </div>

           
          </section>
          
        </div>
      </>

    </div>
  );
};

export default How;
